import { useTranslation } from "react-i18next"
import { formatNumber } from '../utils/formater'
import { Table } from 'reactstrap';
import CustomButtonPrimary from "../Component/CustomButtonPrimary";

const BillBox = (props) => {

    const{ t } = useTranslation()
    
    return (
        <div className="bill-box">
        <Table borderless size="sm" striped>
            <tbody>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`period`}</td>
                    <td className="text-font-size bill-td-box-right"> {props.item.period}</td>
                </tr>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`brojRacuna`}</td>
                    <td className="text-font-size bill-td-box-right">{props.item.invNumber}</td>
                </tr>
                <tr>
                    <td className="text-font-size  bill-td-box-left">{t`iznosKM`}</td>
                    <td className="text-font-size bill-box-value bill-td-box-right">{formatNumber(props.item.amount)}</td>
                </tr>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`preuzmiRacun`}</td>
                    <td className="text-font-size bill-td-box-right">
                        <CustomButtonPrimary className="dark-button" text={t`preuzmiRacun`} icon="lnc-download" onClick={props.downloadAction} />
                    </td>
                </tr>
            </tbody>
        </Table>
    </div>
    )
}

export default BillBox
