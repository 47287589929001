import { useContext } from 'react';
import './Notifications.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {AppContext} from '../AppContext';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../utils/formater';
import { Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next"

const NotificationItem = (props) => {
    const { messageNumber, setMessageNumber } = useContext(AppContext);
    const { t } = useTranslation()

    const checkNotificationNumber = () => {
        if(messageNumber === 1 || messageNumber === 0){
            setMessageNumber(null)
        }else{
            setMessageNumber(messageNumber-1)
        }
    }

    return (
        <div className="card" id="notificationContainer" >
            <div className="card-header" id="notificationTitle">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <label className='text-blue'>{formatDateTime(props.item.notificationSysdate)}</label>
                    </div>
                    <div className="col-12 col-sm-6 d-flex justify-content-center">
                        <Link className=""  to={`/notification/${props.item.notificationId}`}>
                            {!props.item.flagRead ? (
                                <strong onClick = {checkNotificationNumber} className='item-header'>{props.item.header}</strong>
                            ):( <strong className='item-header'>{props.item.header}</strong>)}
                        </Link>
                    </div>
                    {props.item.flagRead ? (
                        <div className="col-2">
                            {props.item.flagRead}
                        </div>
                    ) : (
                        <Tooltip followCursor={true} title={t`readNotification`}>
                            <div className="col-12 col-sm-2" onClick={()=> props.readNotification(props.item.notificationId)}>
                                <FontAwesomeIcon style={{color:"#153a78"}} icon={faEyeSlash} className='icon2' />
                                {props.item.flagRead}
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className="card-body" id="notificationDetails">
                <p className='text-blue card-text' id="notificationParagraph">{props.item.content}</p>
            </div>
            </div>
    )
}

export default NotificationItem
