import { Link } from 'react-router-dom';
import SideItem from './SideItem'
import logo from '../images/logo-primarni.svg';
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap';
import { useContext } from 'react';
import AppContext from '../AppContext';

function SideMenuBig(props) {
    const { t } = useTranslation()
    const { partnerList, selectedPartnerId, setSelectedPartnerId, } = useContext(AppContext);
    let sideMenuClasses = 'side-menu';
    if (props.show) {
        sideMenuClasses = 'side-menu main-open';
    }

    const changePartner = e => {
        setSelectedPartnerId(e.target.value);
    }
    
    return (
            <>
            <div className={sideMenuClasses} >
                <Link to="/"> 
                    <img
                        alt=''
                        src={logo}
                        className='logo-picture mx-auto img-fluid d-block'
                    />
                </Link>
                    <div className="side-content">
                        <div id="first-half">
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-home" to="/" label={t`pocetna`} />
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-location" to="/mjerna-mjesta/" label={t`mjernoMjesto`}></SideItem>
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-bill" to="/racuni/" label={t`racun`}></SideItem>
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-wallet" to="/uplate/" label={t`uplate`}></SideItem>
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-edit" to="/prijava-stanja/" label={t`prijavaStanja`}></SideItem>
                            <SideItem smallMenu={true} clickHandler={props.clickHandler} display={true} icon="lnc-calculations" to="/kalkulator-potrosnje/" label={t`kalkulatorPotrosnje`}></SideItem>
                            <hr style={{backgroundColor:'#286AA0'}}></hr>
                            <div className='mr-auto mt-2 mb-3'>
                                <label style={{color:'#F19021'}} className='ml-3 side-menu-item-label'>{t`krajnjiKupac`}</label>
                                <Input  style={{ background: 'transparent', color: 'lightgray', width:'95%',padding:'5px' }} className="mx-auto" type="select" onClick={e=>e.stopPropagation()} onChange={changePartner} value={selectedPartnerId} name="select">
                                    {partnerList.map( partner => 
                                        <option style={{ background: 'gray', color: 'white' }} key={partner.partnerId} value={partner.partnerId} className=""> {partner.partnerName }</option>)}
                                </Input>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default SideMenuBig
