import React, { useState, useEffect, useContext }  from 'react'
import { Row, Col } from 'reactstrap';
import './LoginView.css';
import FeatureComponent from './FeatureComponent';
import LoginFormComponent from './LoginFormComponent';
import ResetPassword from './ResetPassword';
import SignUpView from './SignUpView';
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import { AUTH_LOGIN,AUTH_GET_ALL_TENANTS } from '../const';
import VerificationView from './VerificationView';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axiosNoAuth from "axios"
import introJs from 'intro.js'
import AppContext from '../AppContext';

const LoginView = ( props ) => {
    const [error, setError] = useState("")
    const [dialog, setDialog] = useState('login')
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [selectedTenant, setSelectedTenant] = useState()
    const { setTenant } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        axiosNoAuth.get(AUTH_GET_ALL_TENANTS)
        .then(resp => {
            let tenantList = resp.data;
            const selectedTenant = tenantList.find((tenant) => {
                return props.match.params.tenant === tenant
            })
        
            if(selectedTenant){
                localStorage.removeItem('token')
                props.history.push('/login/' + props.match.params.tenant)
                setSelectedTenant(selectedTenant)
                setTenant(selectedTenant)
            } else {
                props.history.push('/login')
            }
        })
        .catch(err => {
            console.log(err.response);
        })
        
        
    }, []);

    const labelStyle = {
        color: 'red'
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if ( username === null || username === undefined || username.trim() === '' || password ===null || password === undefined || password.trim() ==='') {
            toast.error(t('requiredPolja')) 
            return;
        }

        axios.post(AUTH_LOGIN, {
            "username": username,
            "password": password,
            "tenantId": selectedTenant
        })
        .then(resp => {
            if(resp.status === 200){
                localStorage.setItem('token', resp.data.token)
                const { from } = props.location.state || {
                    from: { pathname: '/' }
                }
                props.history.push(from)
            } 
        })
        .catch(err => {
            setError(t(err.response.data.message));
            console.log(err);
            toast.error(t(err.response.data.message));
        })
    }

    const helpClickHandler = () => {
        introJs().setOptions({
            steps: [{
                element: document.querySelector('.user-pass'),
                title: t('dobrodosli'),
                intro: t('logovanjeTekst'),
            },
            {
                element: document.querySelector('.forgotPassword'),
                title: t('zaboraviliSteLozinku'),
                intro: t('logovanjeTekst'),
            },
            {
                element: document.querySelector('.register'),
                title: t('registrujteSe'),
                intro: t('logovanjeTekst'),
            },
            {
                element: document.querySelector('.verification'),
                title: t('verifikacijaNaloga'),
                intro: t('logovanjeTekst'),
            }],
            nextLabel: t('sledeca'),
            prevLabel: t('prethodna'),
            doneLabel: t('kraj'),
        
          }).start();
    }

    return (
    <div className='wrapper-login'>
        <div className='layer-login'>
            <div className='container login-view-container d-flex flex-column align-items-center'>
                <Row className='align-items-center justify-content-center mt-auto mb-auto'>
                    <Col xs='10' xl='4' className="sticky-top order-1 order-xl-2 mt-xs-2 mt-4 mb-1 mb-md-3 col-sm-8 col-md-10 col-xl-4">
                    <div className='form-container p-3 p-md-4'>
                        {dialog === 'login' ?
                            <LoginFormComponent
                                username={username}
                                password={password}
                                handleUsernameOnChange ={(e)=>setUsername(e.target.value)}    
                                handlePasswordOnChange ={(e)=>setPassword(e.target.value)}    
                                labelStyle={labelStyle}
                                error={error}
                                handleSubmit={handleSubmit}
                                changeSignUp={()=>setDialog("signUp")}
                                changeResetPassword={()=>setDialog("resetPassword")}
                                changeVerifyAccount={()=>setDialog("verifyAccount")}
                                />
                            : null}
                        
                        {dialog === 'signUp' ?
                        <SignUpView
                                selectedTenant={selectedTenant}
                                showLoginDialog={(e) => setDialog("login")}
                            />
                                            : null}
                                        
                        {dialog === 'resetPassword' ?
                        <ResetPassword
                            selectedTenant={selectedTenant}
                            showLoginDialog={(e) => setDialog("login")}
                            />
                        : null}
                        {dialog === 'verifyAccount' ?
                        <VerificationView
                            selectedTenant={selectedTenant}
                            showLoginDialog={(e) => setDialog("login")}
                            />
                        : null}
                    </div>
                    </Col>

                    <Col xs='9' xl='8' className='order-2 order-xl-2 mt-3 mb-4 col-sm-8 col-md-10 col-xl-8'>
                        <Row className="mt-1 mt-xl-4">
                            <FeatureComponent
                            icon="lnc-user3"
                            title={t`stanjeKupca`}
                            text={t`stanjeKupcaOpis`}
                            />
                            <FeatureComponent
                            icon="lnc-location"
                            title={t`mjernaMjesta`}
                            text={t`mjernaMjestaOpis`}
                            />
                            <FeatureComponent
                            icon="lnc-search"
                            title={t`racuniUplate`}
                            text={t`racuniUplateOpis`}
                            />
                            <FeatureComponent
                            icon="lnc-calculations"
                            title={t`kalkulatorPotrosnje`}
                            text={t`kalkulatorPotrosnjeOpis`}
                            />
                            <FeatureComponent
                            icon="lnc-dashboard"
                            title={t`stanjeBrojila`}
                            text={t`stanjeBrojilaOpis`}
                            />
                        </Row>
                    </Col>
                </Row>
        </div>
            <div className='link-footer-container ml-auto pb-2 d-flex justify-content-end'>
                <div style={{borderRight:'2px solid lightgray'}} className="pr-3">
                <button onClick={helpClickHandler} className="footer-link" style={{color:'lightgray',fontWeight:'bold',background:'transparent',border:'none'}}>{t`pomoc`}</button>
            </div>   
            <div style={{borderRight:'2px solid lightgray'}} className="pr-3">
                <Link to={'/politika-privatnosti'} className="footer-link" style={{color:'lightgray',fontWeight:'bold'}}>
                    <div className='ml-3'>
                        <label className=''>{t`politikaPrivatnosti`}</label>
                    </div>
                </Link>
            </div>   
            <div className='' style={{borderRight:'2px solid lightgray'}}><p className='mx-3'>Copyright LANACO</p></div>
            <div><p className='mx-3'>All Rights Reserved</p></div>
        </div>
    </div>    
</div>
    )
}

export default LoginView
