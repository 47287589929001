import { Form, Input} from 'reactstrap'
import CustomButton from '../Component/CustomButton'
import { useTranslation } from 'react-i18next'

function AddPartnerForm(props) {
    const{ t } = useTranslation()
    return (
        <Form>
            <Input
                    className="fieldsLine"
                    placeholder={t`sifraKupca`}
                    type='text'
                    name='customerNumber'
                    value={props.partnerCode}
                    onChange={(e) => props.setPartnerCode(e.target.value)}
            />
            <div className="settings-modal-button d-flex justify-content-end mt-3">
                <CustomButton className="mb-2" text={t`sacuvaj`} icon="lnc-save" onClick={props.submitModalReguestHandler}></CustomButton>
            </div>
        </Form>
    )
}

export default AddPartnerForm
