import { formatDate, formatNumber } from '../utils/formater'


const TableRow = (props) => {

    return (
        <tr>
            <td className="payment-td"> {formatDate(props.item.paymentDate)}</td>
            <td className="payment-amount"> {formatNumber(props.item.amount)}</td>
            <td className="payment-td payment-text"> {(props.item.placePayment)}</td>
            <td className="payments-value text-left pr-2"> {(props.item.note)}</td>
        </tr>
    )
}

export default TableRow
