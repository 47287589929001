import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge} from 'reactstrap';
import { AppContext } from '../AppContext';
import { useTranslation } from 'react-i18next'
import { authenticationService } from '../authentication/AuthenticationService'
import { Avatar, MenuItem, Box, Tooltip, Menu, IconButton, Divider } from '@mui/material';


const MenuHeader = (props) => {
    const { picture, crmUser, messageNumber } = useContext(AppContext);
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const crmUserComponent = () => {
        return (
            <div id="user-info">
                <h6>{crmUser != null ? crmUser.email:""}</h6>
            </div>  
        )
    }

    const logoutComponent = () => {
        return (
            <Link to={'/login/' + authenticationService.getTenant()} style={{textDecoration:'none'}}>
                <div className='ml-3 side-menu-item'>
                    <i className='side-menu-item-icon mr-2 lnc lnc-undo'></i>
                    <label className='side-menu-item-label'>{t`izlogujSe`}</label>
                </div>
            </Link>
        );
    }

    const settingsComponent = () => {
        return (
            <Link to={'/podesavanja'} style={{textDecoration:'none'}}>
                <div className='ml-3 side-menu-item'>
                    <i className='mr-2 side-menu-item-icon lnc lnc-cog'></i>
                    <label className='side-menu-item-label'>{t`podesavanja`}</label>
                </div>
            </Link>
        );
    }

    return (
        <div className="menu-header d-flex flex-row-reverse align-items-center">
            <Box className='menu-header-button'>
                <Tooltip title={t`podesavanja`}>
                    <IconButton
                        onClick={handleClick}
                        size="large"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 50, height: 50,border:'2px solid #286AA0' }} alt="Ime" src={picture}></Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Tooltip title={t`obavjestenja`}>
                <div>
                    <Link to='/obavjestenja' style={{ textDecoration: 'none' }}>
                        <div className='side-menu-item'>
                            <Badge pill color='danger' className='notification-badge'>{messageNumber}</Badge>
                            <i className='side-menu-item-icon mr-2 lnc lnc-bell'>
                            </i>
                            <label className="side-menu-item-label">
                                {props.label}
                            </label>
                            </div>
                    </Link>
                </div>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                style:{
                    backgroundColor: '#16292D',
                    color:'lightgray',
                    border:'2px solid #286AA0',
                    width:'300px'
                },
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 23,
                    width: 10,
                    height: 10,
                    bgcolor: '#286AA0',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem component={crmUserComponent}></MenuItem>
                <Divider style={{ background: '#286AA0',marginBottom:'1em' }}/>
                <MenuItem component={settingsComponent}></MenuItem>
                <MenuItem component={logoutComponent}></MenuItem>
            </Menu>
        </div>
    )
}

export default MenuHeader