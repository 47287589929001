import React from 'react'

function InfoBox({icon,id,label,value,sufix,className }) {
    let iconClassName = "home-info-box-icon ".concat(icon);
    return (<div className={"no-pad-mar col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 "}>
      <div id={id} className={"home-info-box "+ className}>
        <div className="row">
          <div className="no-pad-mar home-info-icon-content col-lg-2 col-md-2 col-sm-3 col-2"> <i className={iconClassName}></i></div>
          <div className="no-pad-mar col-lg-9 col-md-10 col-sm-9 col-9 master">
            <h4 className="home-h4 home-font home-info-box-label text-uppercase"> {label}</h4>
            <h4 className="home-h4 home-font home-info-box-value">{value} {sufix}</h4>
          </div>
        </div>
      </div>
    </div>)
}

export default InfoBox
