import React,{ useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import { AUTH_VERIFY } from '../const'
import { toast } from 'react-toastify';
import { Input, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter,Button,Col, Row } from 'reactstrap'
import ModalCloseButton from '../Component/ModalCloseButton';

function VerificationView(props) {
    const [email, setEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [verificationCodeInvalid, setVerificationCodeInvalid] = useState(false);
    const [verificationCodeErrorMessage, setVerificationCodeErrorMessage] = useState("");

    const{ t } = useTranslation();

    const onCloseSuccessModal = (e) => {
        setSuccessModalOpen(false);
        props.showLoginDialog();
    }

    const sendAction = (e) => {
        let validFields = true;
    
        if (email === null || email === undefined  || email.trim() === '') {
            setEmailInvalid(true)
            setEmailErrorMessage(t`requiredField`)
            validFields = false
        } else {
            setEmailInvalid(false)
        }

        if (verificationCode === undefined || verificationCode === null || verificationCode.trim() === '') {
            setVerificationCodeInvalid(true)
            setVerificationCodeErrorMessage(t`requiredField`)
            validFields = false
        } else {
            setVerificationCodeInvalid(false)
        }

        if (validFields) {
            verifyAccount();
        }
    }

  const verifyAccount = () => {
      axios.post(AUTH_VERIFY, {
          "username": email,
          "controlNumber": verificationCode,
          "tenantId":props.selectedTenant
      })
      .then(resp => {
          toast.success(t`successfulVerification`)
          props.showLoginDialog();
      })
      .catch(err => {
          if (err.response) {
              console.log(err.response.data.message)
              toast.error(t(err.response.data.message))
          }
      })
    }

    return (
        <div>
          <div>
              <h1 style={{color:'black', fontSize:'24px'}}>{t`verifikacijaNaloga`}</h1>
              <div className="">
                <Input
                  className="fieldsLine"
                  placeholder={t`email`}
                  type='email'
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  invalid={emailInvalid}
                />
                <FormFeedback className="w-100">{emailErrorMessage}</FormFeedback>
                
              <div className="mt-2">
                <Input
                    className="fieldsLine"
                    placeholder={t`verifikacioniKod`}
                    type='text'
                    name='verificationCode'
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    invalid={verificationCodeInvalid}
                />
                <FormFeedback className="w-100">{verificationCodeErrorMessage}</FormFeedback>
              </div>
          </div>
              <Row className='p-xs-0 p-xl-12 mt-4'>
                <Col>
                  <button
                    className='btn dark-button'
                    onClick={props.showLoginDialog}
                  >{t`nazad`}</button>
                </Col>
                <Col>
                  <button
                    className='btn dark-button float-right'
                    onClick={sendAction}
                  >{t`posalji`}</button>
                </Col>
              </Row>
            </div>
            <Modal centered={true} isOpen={successModalOpen}>
                <ModalHeader  className="modal-header"> {t`dostavljenaNovaLozinkaHeader`} <ModalCloseButton onClickHandler={()=>setSuccessModalOpen(!successModalOpen)}></ModalCloseButton></ModalHeader>
                <ModalBody className="modalBody p-3">
                    {t`dostavljenaNovaLozinka`}
                </ModalBody>
                <ModalFooter>
                    <Button className="submit-button" onClick={onCloseSuccessModal}>{t`potvrdi`}</Button>
                </ModalFooter>
            </Modal>  
      </div>
    )
}

export default VerificationView
