import { Switch, Route } from 'react-router-dom'
import HomeView from '../route_pocetna/HomeView';
import MeasuringPointsView from '../route_mjerna_mesta/MeasuringPointsView'
import RacuniView from '../route_racuni/RacuniView'
import UplateView from '../route_uplate/UplateView'
import PrijavaStanjaView from '../route_prijava_stanja/PrijavaStanjaView'
import KalkulatorPotrosnjeView from '../route_kalkulator_potrosnje/KalkulatorPotrosnjeView'
import SettingsView from '../route_podesavanja/SettingsView' ;
import NotificationCard from '../route_pocetna/NotificationCard';
import AllNotifications from '../route_pocetna/AllNotifications';


export default function MainBody(props) {
  return (
    <Switch>
      <Route exact path='/' component={HomeView} />
      <Route path='/mjerna-mjesta' component={MeasuringPointsView} />
      <Route path='/racuni' component={RacuniView} />
      <Route path='/uplate' component={UplateView} />
      <Route path='/obavjestenja' component={AllNotifications} /> 
      <Route path='/notification/:id' component={NotificationCard} />
      <Route path='/prijava-stanja' component={PrijavaStanjaView} />
      <Route path='/kalkulator-potrosnje' component={KalkulatorPotrosnjeView}/>
      <Route path='/podesavanja' render={()=><SettingsView {...props} />}/>
    </Switch>
  );
}