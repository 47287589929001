import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_END_POINT,
    headers: {'Access-Control-Allow-Origin': 'privatekey'}
});

instance.interceptors.request.use((req => {
  const token = localStorage.getItem('token');
  req.headers.Authorization = token ? `Bearer ${token}` : ''
  
  return req
})); 

export default instance;