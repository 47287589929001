import { useTranslation } from 'react-i18next';

function TableHeaderView() {
    const { t } = useTranslation();
    return (
        <thead>
        <tr className="text-center">
            <th rowSpan={2}>{t`brojMjernogUredjaja`}</th>
            <th rowSpan={2}>{t`datum`}</th>
            <th rowSpan={2}>{t`stanje`}</th>
        </tr>
    </thead>
    )
}

export default TableHeaderView
