import './PrijavaStanjaView.css'
import { useTranslation } from 'react-i18next'

const PosljednjeOcitanoStanje = (props) => {
    
    const{ t } = useTranslation()

    return (
        <div className="card whole-box">
            <h5 className="card-header header-box text text-center">{t`posljednjeOcitanoStanje`}</h5>
            <div className="card-body body-box d-flex justify-content-center">
                <label className="text-blue d-block">{t`stanje`} (м³): {props.ocitanoStanje == null?'-':props.ocitanoStanje}</label>
            </div>
        </div>
    )
}

export default PosljednjeOcitanoStanje
