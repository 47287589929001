import React, { useState } from 'react'
import { Row, Col, Input, FormFeedback } from 'reactstrap'
import './ResetPassword.css'
import axios from '../utils/axios'
import { AUTH_FORGOT_PASSWORD } from '../const'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';

const ResetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const{ t } = useTranslation();

    const sendAction = (e) => {
      if (email == null || email.trim() === "") {
        setEmailErrorMessage(t`requiredField`)
        setEmailInvalid(true)
      } else {
        setEmailInvalid(false)
        forgotPassword()
      }    
    }

  const forgotPassword = (e) => {
      axios.put(AUTH_FORGOT_PASSWORD, {
        "email": email,
        "tenantId":props.selectedTenant
    })
    .then(resp => {
        toast.success(t`successfulForgotPassword`)
        props.showLoginDialog();
    })
    .catch(err => {
        if (err.response) {
            console.log(t(err.response.data.message))
            toast.error(t(err.response.data.message))
        }
    })
    }

    return (
            <div>
                <h1 style={{color:'black', fontSize:'24px'}}>{t`zaboraviliSteLozinku`}</h1>
                <h1 className='text text-dark'>{t`textInstrukcijeZaLozinku`}</h1>
                <div className="row col-12 nopadding d-flex justify-content-between pb-4">
                  <Input
                      className="fieldsLine"
                      placeholder={t`email`}
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={emailInvalid}
                  />
                  <FormFeedback className="w-100">{emailErrorMessage}</FormFeedback>
                </div>
                <Row className='p-xs-0 p-xl-12'>
                  <Col>
                    <button
                      className='btn dark-button'
                      onClick={props.showLoginDialog}
                    >{t`nazad`}</button>
                  </Col>
                  <Col>
                    <button
                      className='btn dark-button float-right'
                      onClick={sendAction}
                    >{t`posalji`}</button>
                  </Col>
                </Row>
            </div>)
}

export default ResetPassword
