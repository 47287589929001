import { useState, useEffect, useContext} from 'react'
import SideMenuSmall from './SideMenuSmall'
import SideMenuBig from './SideMenuBig'
import MainContent from './MainContent'
import profileImg from '../images/profil-picture.png'
import { AppContext } from '../AppContext'
import { NOTIFICATION_UNREAD_COUNT, MJERNA_MJESTA_ALL_URL, CRM_USER_GET_INFO, GET_USER_PICTURE } from '../const'
import './MainView.css'
import axios from '../utils/axios'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MenuHeader from './MenuHeader'
import { authenticationService } from '../authentication/AuthenticationService'

function MainView() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { setPicture, setMessageNumber, selectedPartnerId,
     setPartnerList , setCrmUser, setSelectedPartnerId, setMjernoMjestoList,
     setLanguage,tenant,setTenant } = useContext(AppContext);
  const { i18n } = useTranslation();

  const getUserPicture = async () => {
    try{
      let userPicture = await axios.get(GET_USER_PICTURE);
      userPicture = "data:image/jpeg;base64," + userPicture.data;
      setPicture(userPicture);
    } catch (error) {
      console.log(error);
      setPicture(profileImg);
    }
  }

  const countNotifications = async () => {
    try {
      let unreadNotificationCount = await axios.get(NOTIFICATION_UNREAD_COUNT);
      unreadNotificationCount = unreadNotificationCount.data;
      setMessageNumber(unreadNotificationCount);
    } catch (error) {
      console.log(error)
    }
  }
  
  const getUserInfo = async () => {
    try {
      let userInfo = await axios.get(CRM_USER_GET_INFO);
      userInfo = userInfo.data;
      i18n.changeLanguage(userInfo.languageCode);
      let defaultPartner = userInfo.listPartners.find(partner => partner.defaultPartnerCode)
      setCrmUser(userInfo);
      setSelectedPartnerId(defaultPartner?defaultPartner.partnerId:userInfo.listPartners[0].partnerId);
      setPartnerList(userInfo.listPartners);
      setLanguage(userInfo.languageCode);
    } catch (error) {
        console.log(error)
    }
  };

  useEffect(() => {
    getUserInfo();
    getUserPicture();
    countNotifications();
  }, [])
  
  useEffect(() => {
    if (selectedPartnerId) {
      axios.get(MJERNA_MJESTA_ALL_URL(selectedPartnerId)).then(resp => {
        setMjernoMjestoList(resp.data)
      }).catch(error => {
        console.log(error);
      });
    }
  },[selectedPartnerId]) 
 
  useEffect(()=>{
    if(!tenant){
      let tempTenant = authenticationService.getTenant();
      if(tempTenant)
      setTenant(tempTenant);
    }
  },[tenant])

  return (
        <div className='wrapper'>
          <div className='layer'>   
            <MenuHeader></MenuHeader>
            <SideMenuBig sideMenuToggleClickHandler={()=>setSideMenuOpen(!sideMenuOpen)} clickHandler={()=>setSideMenuOpen(false)} show={sideMenuOpen} />
            <SideMenuSmall sideMenuToggleClickHandler={()=>setSideMenuOpen(!sideMenuOpen)} clickHandler={()=>setSideMenuOpen(false)} show={sideMenuOpen} />
            <MainContent refreshData={getUserInfo} />
          </div>
        </div>
  )
}

export default withRouter(MainView)
