import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import SwitchButton from './SwitchButton'

const BasicSettingsForm = (props) => {  
    const { t } = useTranslation()
    return (
        <Form>
            <FormGroup>
                <SwitchButton labelClassName="text-blue" checked={props.notificationOnEmailEnabled} switchAction={props.notificationEmailSwitchHandler} label={t`obavjestenjeNaEmail`} />
            </FormGroup>
            <FormGroup>
                <Label className="text-blue" for="exampleSelect">{t`odaberiPismo`}</Label>
                <Input className="settings-input-combobox" type="select" onChange={props.changeLanguage} value={props.language} name="select" id="exampleSelect">
                    <option value='cir'>{t`cirilica`}</option>
                    <option value='lat'>{t`latinica`}</option>
                </Input>
            </FormGroup>
        </Form>
  );
};

export default BasicSettingsForm;
