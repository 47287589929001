import { Form, InputGroup } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PasswordField from '../Component/PasswordField/PasswordField'

function ChangePasswordForm(props) {
    const { t } = useTranslation()
    
    return (
        <Form>
            <div className="row col-12 nopadding d-flex justify-content-between">
                <InputGroup className="mb-1 mb-md-3 col-md-5 col-xl-12 nopadding">
                    <PasswordField placeholder={t`staraSifra`} value={props.passwordData.oldPassword} name="oldPassword" type="password" className="w-100 fieldsLine" onChange={props.handleChangePassword}></PasswordField>
                </InputGroup>

                <InputGroup className="mb-1 mb-md-3 col-md-5 col-xl-12 nopadding">
                    <PasswordField placeholder={t('novaSifra')} value={props.passwordData.newPassword} name="newPassword" type="password" className="w-100 fieldsLine" onChange={props.handleChangePassword}></PasswordField>
                </InputGroup>
                    
                <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                    <PasswordField placeholder={t('ponovljenaSifra')} value={props.passwordData.repPassword} name="repPassword" className="w-100 fieldsLine" onChange={props.handleChangePassword}></PasswordField>
                </InputGroup>
            </div>
        </Form>
    )
}

export default ChangePasswordForm
