import React, { useContext, useState } from 'react'
import { Col, Row, Input, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { CALCULATE_MEASUREMENT,CALCULATE_CONSUMPTION } from '../const';
import "./KalkulatorPotrosnjeView.css"
import CustomButtonPrimary from '../Component/CustomButtonPrimary'
import UtrosakComponent from './UtrosakComponent'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import AppContext from '../AppContext';
import { toast } from 'react-toastify';
import ModalCloseButton from '../Component/ModalCloseButton';


const KalkulatorPotrosnjeForma = (props) => {
    const { t } = useTranslation();
    const {selectedPartnerId} = useContext(AppContext)
    const [selectedValue, setSelectedValue] = useState("option1");
    const [utrosakMetriKubniVisible, setUtrosakMetriKubniVisible] = useState(false);
    const [utrosakOcitanjeVisible, setUtrosakOcitanjeVisible] = useState(false);

    const [calculatedConsumption, setCalculatedConsumption] = useState({
        niv: 0,
        niv_pdv: 0,
        nom: 0,
        nom_pdv: 0,
        nzv: 0,
        nzv_pdv: 0,
        price_sewerage: 0,
        price_sewerage_pdv: 0,
        price_water: 0,
        price_water_pdv: 0,
        quantity: 0,
        sum_pdv: 0,
        sum_with_pdv: 0,
        sum_without_pdv:0
    })

 

    const openModal = () => {
        setUtrosakMetriKubniVisible(selectedValue === "option1" ? true : false);
        setUtrosakOcitanjeVisible(selectedValue === "option2" ? true : false);
    }

    const closeModal = () => {
        setUtrosakMetriKubniVisible(false);
        setUtrosakOcitanjeVisible(false);
    }

    const izracunaj = (evnet) => {  
        if(selectedValue==="option1" && props.selectedMeasurementPointId !==''&& props.selectedMeasurementPointId !==null&& props.selectedMeasurementPointId !== undefined && props.quantity>0){
            axios.post(CALCULATE_CONSUMPTION,{
                "partnerId": selectedPartnerId,
                "measurementPointId": props.selectedMeasurementPointId,
                "quantity":  props.quantity
            })
            .then(resp => {
                setCalculatedConsumption(resp.data)
            })
            .then(e => {
                openModal()
            })
            .catch(err => {
                console.log(err)
            })
        } else if(selectedValue==="option2" && props.selectedMeasurementPointId!==''&& props.selectedMeasurementPointId !==null&& props.selectedMeasurementPointId !== undefined && props.quantity>0){
            axios.post(CALCULATE_MEASUREMENT,{
                "partnerId": selectedPartnerId,
                "measurementPointId": props.selectedMeasurementPointId,
                "quantity":  props.quantity
            })
            .then(resp => {
                setCalculatedConsumption(resp.data)
            })
            .then(e => {
                openModal()
            })
            .catch(err => {
                console.log(err)
            })
        } else {
            toast.error(t`requiredPolja`)
        }
    }

    const radioButtonSelectionChange = (event) => {
        setSelectedValue(event.target.value);
    }
    

    return (
            <div>
                <div className="card">
                    <Col className="card-header">
                        <h4 className="titleKalkulatorPotrosnje">{t`izborKalkulatora`}:</h4>
                        <div className="col-sm-12">
                            <div className="radio">
                                <label className="text-blue kalkPotrosnjeFont">
                                    <Input type="radio" value="option1" checked={selectedValue === "option1"} onChange={radioButtonSelectionChange}></Input>
                                    {t`kalkPotrosnjeKilovatCasovi`}
                                </label>
                            </div>
                            <div className="radio">
                                <label className="text-blue kalkPotrosnjeFont">
                                    <Input type="radio" value="option2" checked={selectedValue === "option2"} onChange={radioButtonSelectionChange}></Input>
                                    {t`kalkPotrosnjeOcitanje`}
                                </label>
                            </div>
                        </div>
                    </Col>
                    <Row className="card-body body-box">
                        <Col xs='12' className={!props.formEnabled && selectedValue==="option2"?"mb-3 d-block":"d-none"}>
                            <div>           
                                <label className="w-50 d-inline mr-3 text-danger font-weight-bold">{t`mjestoNemaVodomjer`}</label>
                            </div>
                        </Col>
                        <Col xs='12' md='3' className="d-flex flex-sm-column flex-md-row align-items-center mr-5">
                            <div className="text-blue text-nowrap mr-3">{selectedValue==="option1" ? t`utrosak` : t`ocitanje`} (м³):</div>
                            <Input disabled= {props.formEnabled === false && selectedValue==="option2" ?true:false} value={props.quantity} onChange={props.insertQuantityValue} className="font-weight-bold w-50" type="number"></Input> 
                        </Col>
                        <Col xs='12' md='3' className="text-center mt-3 mt-md-1">
                            <CustomButtonPrimary className="dark-button" disabled= {!props.formEnabled && selectedValue==="option2"} onClick={izracunaj} text={t`izracunaj`} icon="lnc-check"/>
                        </Col>
                             
                    </Row>
                    
                </div>
                
                <br></br>

                <div>
                    <Modal centered={true} isOpen={utrosakMetriKubniVisible}>
                        <ModalHeader toggle={()=>setUtrosakMetriKubniVisible(!utrosakMetriKubniVisible)} className="modal-header">{t`proracunatiUtrosak`}<ModalCloseButton onClickHandler={()=>setUtrosakMetriKubniVisible(!utrosakMetriKubniVisible)}></ModalCloseButton></ModalHeader>
                        <ModalBody className="modalBody">
                            <UtrosakComponent className="p-0" data={calculatedConsumption} closeModal={closeModal}></UtrosakComponent>
                        </ModalBody>
                    </Modal>
                </div>
                <div>
                    <Modal centered={true} isOpen={utrosakOcitanjeVisible}>
                        <ModalHeader toggle={()=>setUtrosakOcitanjeVisible(!utrosakOcitanjeVisible)} className="modal-header">{t`proracunatiUtrosak`}<ModalCloseButton onClickHandler={()=>setUtrosakOcitanjeVisible(!utrosakOcitanjeVisible)}></ModalCloseButton></ModalHeader>
                        <ModalBody className="modalBody">
                            <UtrosakComponent data={calculatedConsumption} closeModal={closeModal}></UtrosakComponent>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <label className='text-lightgray'>*{t`napomenaPrethodniSaldo`}</label><br></br>
                    <label className='text-lightgray'>*{t`napomenaOdstupanjeDecimale`}</label><br></br>
                    <label className='text-lightgray'>*{t`napomenaNezvanicnaInformacija`}</label>
                </div>

            </div>
    )
}

export default KalkulatorPotrosnjeForma
