import React, { useContext} from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext';
import { Badge } from 'reactstrap';
import { useLocation } from 'react-router-dom'
import { Tooltip } from '@mui/material';

const SideItem = (props) => {
    const location = useLocation();
    let isActive = location.pathname === props.to;
    let inverse = props.inverse ? 'side-menu-item-inverse ' : 'side-menu-item ';
    let activ = isActive ? inverse.concat('side-menu-item-activ ') : inverse;
    let display = props.display ? 'd-block' : 'd-none'
    let className = activ.concat(display);
    let iconClass = 'lnc side-menu-item-icon '.concat(props.icon)
    let smallMenu = props.smallMenu;

    const { messageNumber } = useContext(AppContext);

    if(props.to){
        return (
         <Tooltip followCursor={true} title={props.label}>
            <div className={className} onClick={props.clickHandler}>
                    <Link to={props.to} style={{ textDecoration: 'none' }}>
                        <div className={className}>
                        {props.hasBadge &&
                            <Badge pill color='danger' className='notification-badge'>{messageNumber}</Badge>}
                            <i className={iconClass}>
                            </i>
                            {(smallMenu) && <label className="side-menu-item-label">
                                {props.label}
                            </label>} 
                        </div>
                    </Link>
                </div>
            </Tooltip>
        )
    } else {
        return (
            <Tooltip followCursor={true} title={props.label}>
                <div className={className} onClick={props.clickHandler} style={{cursor: 'pointer'}}>
                    <div className={className}>
                    {props.hasBadge &&
                        <Badge pill color='danger' className='notification-badge'>{messageNumber}</Badge>}
                        <i className={iconClass}>
                        </i>
                        {(smallMenu) && <label className="side-menu-item-label">
                            {props.label}
                        </label>}
                    </div>
                </div>
             </Tooltip>
        )
    }
};

export default SideItem;
