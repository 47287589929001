import React, { useState, useContext, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import { CHANGE_USER_PICTURE, CHANGE_PASSWORD,CHANGE_SETTINGS,GET_CRM_USER_PARTNER_LIST,ADD_PARTNER_CODE,VERIFY_PARTNER,CHANGE_INVOICE_ON_EMAIL,DEACTIVATE_PARTNER } from '../const'
import "./PodesavanjaView.css"
import { AppContext } from '../AppContext'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import AddPartnerForm from './AddPartnerForm'
import ChangePasswordForm from './ChangePasswordForm'
import { formatDate } from "../utils/formater";
import { toast } from 'react-toastify';
import VerificationForm from './VerificationForm'
import BasicSettingsForm from './BasicSettingsForm'
import CustomButtonPrimary from '../Component/CustomButtonPrimary'
import ModalCloseButton from '../Component/ModalCloseButton'


const SettingsView = (props) => {
    const [verifyModalOpen, setVerifyModalOpen] = useState(false)
    const [newPartnerModalOpen, setNewPartnerModalOpen] = useState(false)
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
    const [changeSendEmailModalOpen, setChangeSendEmailModalOpen] = useState(false)
    const [invoiceOnEmailEnabled, setInvoiceOnEmailEnabled] = useState(false)
    const [notificationOnEmailEnabled, setNotificationOnEmailEnabled] = useState(false)
    const [partnerCode, setPartnerCode] = useState(null)
    const [selectedPartnerId,setSelectedPartnerId] = useState(null)
    const [verificationCode,setVerificationCode] = useState(null)
    const [partnerList, setPartnerList] = useState([
        {
            partnerId: null,
            partnerCode: null,
            partnerName: null,
            defaultPartnerCode: null,
            dateStart: null,
            dateEnd: null,
            dateRequest: null,
            invoiceOnEmailEnabled: null,
            invoiceOnEmailChangeable: null,
            requestStatus: null
        }
    ])
    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        newPassword: "",
        repPassword: ""
    });
    const fileInput = React.createRef()
    const{ t, i18n } = useTranslation()
    const { setPicture, picture, language, setLanguage } = useContext(AppContext);

    const getPartnerList = () => {
        axios.get(GET_CRM_USER_PARTNER_LIST).then(resp => { 
            setPartnerList(resp.data)
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        getPartnerList()
    }, []);
    
    const fileSelectedHandler = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
        const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
        if(base64String != null)
            changePicture(base64String)
        };
        reader.readAsDataURL(file);
    }

    const changePicture = ( base64String ) => {
        axios.put(CHANGE_USER_PICTURE, {
            "name":"slika",
            "file": base64String
        })
        .then(e => {
            setPicture("data:image/jpeg;base64,"+base64String)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const clickOnUploadFile = (e) => {
        fileInput.current.click()
    }

    const toggleModalRequest = () => {
        setNewPartnerModalOpen(!newPartnerModalOpen);
    }

    const toggleModalSendEmail = () => {
        setChangeSendEmailModalOpen(!changeSendEmailModalOpen)
    }

    const notificationEmailSwitchHandler = () => {
        setNotificationOnEmailEnabled(!notificationOnEmailEnabled)
    }

    const invoiceEmailSwitchHandler = () => {
        setInvoiceOnEmailEnabled(!invoiceOnEmailEnabled)
    }

    const changeLanguage = (e) => {
        setLanguage(e.target.value)
        i18n.changeLanguage(e.target.value)
    }

    const handleChangePassword = (e) => {
        const value = e.target.value;
        setPasswordData({
          ...passwordData,
          [e.target.name]: value
        });
    }

    const submitChangePasswordHandler = () => {
        let emptyFields = null;
        emptyFields = Object.keys(passwordData).find(field => {
            return passwordData[field] === null || passwordData[field].trim() === ""
        })
        if (emptyFields == null) {
            axios.put(CHANGE_PASSWORD, {
                "oldPassword":passwordData.oldPassword,
                "newPassword": passwordData.newPassword,
                "repeatedNew":passwordData.repPassword
            })
            .then(resp => {
                setPasswordData({
                    oldPassword: "",
                    newPassword: "",
                    repPassword: ""
                })
                toast.success(t`uspjesnoPromjenjenaLozinka`);
            })
            .catch(err => {
                if (err.response) {
                    console.log(t(err.response.data.message))
                    toast.error(t(err.response.data.message));
                }
            })
        } else {
            toast.error(t`requiredPolja`)
        }
     }

    const submitNewPartnerHandler = (e) => {
        if (partnerCode === null || partnerCode.trim() === '') {
            toast.error(t('requiredPolja'))
        }else{
            axios.post(ADD_PARTNER_CODE, {
                "partnerCode":partnerCode
            }).then((resp)=>{
                if (resp.status === 200) {
                    getPartnerList();
                    toast.success(t`zahtjevPoslat`)
                    setNewPartnerModalOpen(false)
                }
            }).catch(error => {
                console.log(error);
                toast.error(t(error.response.data.message))
            })
        }
    }

    const toggleModalVerify = (partnerId) => {
        setVerifyModalOpen(!verifyModalOpen)
        setSelectedPartnerId(partnerId)
    }

    const toggleModalDeactivate = (partnerId) => {
        setDeactivateModalOpen(!deactivateModalOpen)
        setSelectedPartnerId(partnerId)
    }

    const submitVerificationCode = (e) => {
        if (verificationCode === null || verificationCode.trim() === '') {
            toast.error(t('requiredPolja'))
        }else{
            axios.post(VERIFY_PARTNER, {
                "controlNumber":verificationCode,
                "partnerId":selectedPartnerId
            }).then((resp)=>{
                if (resp.status === 200) {
                    toast.success(t`successfulVerificationPartner`)
                    getPartnerList();
                    props.refreshData();
                }
                toggleModalVerify();
            }).catch(error => {
                console.log(error);
                toast.error(t(error.response.data.message))
            })
        }
    }

    const submitSettingsHandler = (e) => {
        let languageId = language === 'lat'? 2 : 1;
        let dto = {
            "notificationOnEmailEnabled":notificationOnEmailEnabled,
            "languageId":languageId
        }

        axios.put(CHANGE_SETTINGS, dto)
        .then(e => {
            toast.success(t`uspjesnoPromjenjenaPodesavanja`);
        })
        .catch(err => {
            toast.success(err);
        })
    }

    const switchAction = (event) => {
        let partnerId = Number.parseInt(event.target.name);
        setSelectedPartnerId(partnerId)
        toggleModalSendEmail();
    }  

    const sendOnEmailAction = () => {
        let partner = partnerList.find(partner => partner.partnerId === selectedPartnerId);
        axios.put(CHANGE_INVOICE_ON_EMAIL(partner.partnerId, !partner.invoiceOnEmailEnabled))
            .then(e => {
                toast.success(t("successChangeSendOnEmail"))
                toggleModalSendEmail();
                getPartnerList();
            })
            .catch(err => {
                toast.error(err);
            });
    }

    const deactivatePartner = () => {
        axios.put(DEACTIVATE_PARTNER(selectedPartnerId))
        .then(e => {
            toggleModalDeactivate();
            toast.success(t("deaktivacijaUspjesna"))
            getPartnerList();
        })
        .catch(err => {
            toast.error(err);
        });
    }
 
        return (
            <>
                <div className="container-fluid">
                    <h4 className="text-lightgray">{t`podesavanja`}</h4><br/>
                    <div className="row">
                        <div className="col-12 col-md-4 d-flex flex-column">
                            <div className="card whole-box">
                                <div className="card-header header-box d-flex">
                                    <i className="home-info-card-icon lnc lnc-image fa-2x mr-3"></i>
                                    <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`promjenaSlike`}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="image-container" style={{height:"250px",width:"60%",marginLeft:"auto",marginRight:"auto"}}>
                                        <img alt="" src={picture} className="mx-auto img-responsive img-fluid d-block " />
                                        <button className="btn-upload" onClick={clickOnUploadFile} ><i className="lnc lnc-upload2 icon-upload"></i></button>
                                    </div>
                                    <input style={{ display: 'none' }} type="file" ref={fileInput} onChange={fileSelectedHandler}></input>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 d-flex flex-column">
                            <div className="card whole-box">
                                <div className="card-header header-box d-flex">
                                    <i className="home-info-card-icon lnc lnc-cog fa-2x mr-3"></i>
                                    <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`opstaPodesavanja`}</h4>
                                </div>
                                <div className='card-body'>
                                    <BasicSettingsForm
                                        language={language}
                                        changeLanguage={changeLanguage}
                                        notificationEmailSwitchHandler={notificationEmailSwitchHandler}
                                        notificationOnEmailEnabled={notificationOnEmailEnabled}
                                        invoiceOnEmailEnabled={invoiceOnEmailEnabled}
                                        invoiceEmailSwitchHandler={invoiceEmailSwitchHandler}
                                    >
                                    </BasicSettingsForm>
                                </div>
                                <div className='card-footer d-flex justify-content-end mt-auto'>
                                    <CustomButtonPrimary className="dark-button" onClick={submitSettingsHandler} text={t`sacuvaj`} icon="lnc-save"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 d-flex flex-column">
                            <div className="card whole-box">
                                <div className="card-header header-box d-flex">
                                    <i className="home-info-card-icon lnc lnc-scan-number fa-2x mr-3"></i>
                                    <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`promjenaLozinke`}</h4>
                                </div>
                               <div className='card-body'>
                                    <ChangePasswordForm
                                        passwordData={passwordData}
                                        handleChangePassword={handleChangePassword}
                                        submitChangePasswordHandler={submitChangePasswordHandler}
                                    ></ChangePasswordForm>
                                </div>
                                <div className='card-footer d-flex justify-content-end'>
                                    <CustomButtonPrimary className="dark-button" onClick={submitChangePasswordHandler} text={t`sacuvaj`} icon="lnc-save"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mx-auto w-100 d-flex flex-column mt-5">
                            <div className="card whole-box"> 
                                <div className="card-header header-box d-flex">
                                    <i className="home-info-card-icon lnc lnc-user fa-2x mr-3"></i>
                                    <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`krajnjiKupci`}</h4>
                                    <CustomButtonPrimary style={{fontSize:'15px'}} className="p-1 ml-auto dark-button" onClick={toggleModalRequest} text={t`zahtjevKrajnjiKupac`} icon="lnc-plus"/>
                                </div>
                                <div className='p-3'>
                                    <div className="krajnji-kupac-table">
                                        <Table striped borderless responsive>
                                            <thead className="payment-thead">
                                                <tr className="text-center">
                                                    <th className="payment-th text-left">{t`naziv`}</th>
                                                    <th className="payment-th text-center">{t`dateRequest`}</th>
                                                    <th className="payment-th text-center">{t`dateStart`}</th>
                                                    <th className="payment-th text-center">{t`dateEnd`}</th>
                                                    <th className="payment-th text-center">{t`racunNaEmail`}
                                                        <label style={{fontSize:'8px'}}>({t`promjenaPrimaRacunMejlom`})</label>
                                                    </th>
                                                    <th className="payment-th text-left">{t`status`}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partnerList.map(partner => {
                                                return <tr key={partner.partnerId}>
                                                            <td>{partner.partnerCode+" - "+partner.partnerName}</td>
                                                            <td className='text-center'>{partner.dateRequest?formatDate(partner.dateRequest):'-'}</td>
                                                            <td className='text-center'>{partner.dateStart?formatDate(partner.dateStart):'-'}</td>
                                                            <td className='text-center'>{partner.dateEnd?formatDate(partner.dateEnd):'-'}</td>
                                                            <td className='text-center'>
                                                                <label className="settings-switch" style={{float:'none'}}>
                                                                    <input disabled={partner.invoiceOnEmailChangeable === 0} type="checkbox" name={partner.partnerId} checked={partner.invoiceOnEmailEnabled} onChange={switchAction} />
                                                                    <span className="settings-slider settings-round"></span>
                                                                </label>
                                                            </td>
                                                            <td className='text-left'>{partner.requestStatus}</td>
                                                            <td className='text-left d-flex'>
                                                                <button disabled={partner.requestStatus === 'Registrovano'} outline color="primary" size="sm" className="mr-2" onClick={() => toggleModalVerify(partner.partnerId)}>{t`aktivacija`}</button>
                                                                <button disabled={partner.dateEnd !== null} outline color="danger" size="sm" onClick={() => toggleModalDeactivate(partner.partnerId)}>{t`deaktivacija`}</button>
                                                            </td>
                                                        </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <Modal centered={true} isOpen={verifyModalOpen} onClosed={()=>setVerificationCode(null)} backdrop>
                        <ModalHeader toggle={toggleModalVerify} className="modal-header">{t`verifikacijaNaloga`} <ModalCloseButton onClickHandler={toggleModalVerify}></ModalCloseButton></ModalHeader>
                        <ModalBody>
                            <VerificationForm
                                setVerificationCode={setVerificationCode}
                                submitModalReguestHandler={submitVerificationCode}
                            ></VerificationForm>
                        </ModalBody>
                    </Modal>

                    <Modal centered={true} isOpen={newPartnerModalOpen} backdrop>
                        <ModalHeader toggle={toggleModalRequest} className="modal-header"><p style={{width:'90%'}}>{t`dodavanjeNovogPartnera`}</p> <ModalCloseButton onClickHandler={toggleModalRequest}></ModalCloseButton></ModalHeader>
                        <ModalBody>
                            <AddPartnerForm 
                                partnerCode={partnerCode}
                                setPartnerCode={setPartnerCode}
                                submitModalReguestHandler={submitNewPartnerHandler}></AddPartnerForm>
                        </ModalBody>
                    </Modal>

                    <Modal centered={true} isOpen={deactivateModalOpen} backdrop>
                        <ModalHeader toggle={toggleModalDeactivate} className="modal-header">{t`deaktivacijaNaloga`} <ModalCloseButton onClickHandler={toggleModalDeactivate}></ModalCloseButton></ModalHeader>
                        <ModalBody>
                            <p className='d-block'>
                                {t`deaktivacijaPotvrda`}
                            </p>
                            <div className='d-flex justify-content-center'>
                                <CustomButtonPrimary  className="dark-button" onClick={deactivatePartner} text={t`potvrdi`} icon="lnc-check"/>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal centered={true} isOpen={changeSendEmailModalOpen} backdrop>
                        <ModalHeader toggle={toggleModalSendEmail} className="modal-header">{t`racunNaEmail`} <ModalCloseButton onClickHandler={toggleModalSendEmail}></ModalCloseButton></ModalHeader>
                        <ModalBody>
                            <p className='d-block'>
                                {t`changeSendOnEmail`}
                            </p>
                            <div className='d-flex justify-content-center'>
                                <CustomButtonPrimary  className="dark-button" onClick={sendOnEmailAction} text={t`potvrdi`} icon="lnc-check"/>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </>
        )
    }

export default SettingsView
