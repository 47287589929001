import PosljednjeOcitanoStanje from './PosljednjeOcitanoStanje'
import PrijavaNovogStanja from './PrijavaNovogStanja'
import Utrosak from './Utrosak'
import { Row, Col } from "reactstrap"
import { SUBMIT_USER_MEASUREMENT } from '../const'
import './PrijavaStanjaView.css'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import { toast } from 'react-toastify';
import CustomButtonPrimary from '../Component/CustomButtonPrimary'

const PrijavaStanjaForma = (props) => {
    const { t } = useTranslation()
    
    const buttonPrijaviClicked = () => {
        if (props.utrosak !== undefined) {
            axios.post(SUBMIT_USER_MEASUREMENT, {
                partnerId: props.partnerId,
                measurementPointId: props.mjernoMjestoId,
                quantity:props.prijavljenoStanje,
                consumption: props.utrosak,
                valid: true,
                accepted: true
            }).then(resp =>{
                toast.success(t`uspjesnaPrijavaStanja`)
            }).catch((error) => {
                console.log(error.response.data.message);
                toast.error(t(error.response.data.message))
                props.setFormEnabled(false);
            })
        } else {
            toast.error(t`requiredPolja`)
        }
    }
    return (

        <div style={{backgroundColor:"transparent"}} className="card card-box m-0">
            <Row className="card-body mt-3">
                <Col className="" xs='12' md='4' xl='4'>
                    <PosljednjeOcitanoStanje ocitanoStanje={props.ocitanoStanje}></PosljednjeOcitanoStanje>
                </Col>
                <Col className="mt-3 mt-md-0" xs='12' md='4' xl='4'>
                    <PrijavaNovogStanja formEnabled={props.formEnabled}
                        isAlreadySubmitted={props.isAlreadySubmitted}
                        prijavljenoStanje={props.prijavljenoStanje}
                        novoStanjeOnChange={props.novoStanjeOnChange}></PrijavaNovogStanja>
                </Col>
                <Col className="mt-3 mt-md-0" xs='12' md='4' xl='4'>
                    <Utrosak ocitanoStanje={props.ocitanoStanje} utrosak={props.utrosak}></Utrosak>
                </Col>
            </Row>
            <div style={{backgroundColor:"transparent"}} className="card-footer footer-box d-flex d-flex flex-row-reverse justify-content-center mt-3" text={t`prijaviStanje`} disabled={true} icon="lnc-check">
                <CustomButtonPrimary className="btn light-button" onClick={buttonPrijaviClicked} disabled={!props.formEnabled} icon="lnc-check" text={t`prijaviStanje`}></CustomButtonPrimary>
            </div>
        </div>
    )
}

export default PrijavaStanjaForma
