import { useState } from 'react'
import { Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './PasswordField.css';

const PasswordField = (props) => {

    let [isVisible, setIsVisible] = useState(false);
    const toggle = () => {
      setIsVisible(!isVisible);
    }
  
    return (
      <div className='d-flex w-100 align-items-center'>
        <Input {...props} type={isVisible ? 'text' : 'password'}></Input>
        <FontAwesomeIcon className="visible-icon" icon={isVisible ? faEyeSlash : faEye} onClick={toggle} />
      </div>
  )
}

export default PasswordField