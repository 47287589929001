const SwitchButton = (props) => {
    return (
        <div className="d-flex  switch-button justify-content-between mt-2">
            <label className={props.labelClassName}>{props.label}</label>
            <label className="settings-switch">
                <input {...props} type="checkbox" checked={props.checked} onChange={props.switchAction} />
                <span className="settings-slider settings-round"></span>
            </label>
        </div>
    )
}

export default SwitchButton
