import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ONE_NOTIFICATION, ONE_NOTIFICATION_READ } from '../const'
import { formatDateTime } from '../utils/formater'
import { Link } from 'react-router-dom'
import axios from '../utils/axios';

const NotificationCard = (props) => {

    const[notification, setNotification] = useState({})

    useEffect(() => {    
        const { id } = props.match.params
        axios.get(ONE_NOTIFICATION(id, 1))
        .then(resp => {
          setNotification(resp.data)
        })
        .catch(error => {
          console.log("Greška:" + error)
        })
          oneNotificationRead()
    }, [])

    const oneNotificationRead = (e) => {
        const { id } = props.match.params
        axios.put(ONE_NOTIFICATION_READ(1, id))
        .catch(error => {
          console.log("Greška:" + error)
        })
    }

    return (
        <Container fluid className='one-notification'>
         <Link to={`/obavjestenja`}>
                <i style={{
                  color:'#F19021'
                }} className='lnc lnc-undo lnc-icon'></i>
          </Link>
       <Row className='notification-header'>
         <Col><h5 className='text-lightgray'>{notification.header}</h5></Col>
       </Row>
        <Row className='first-row'>
          <Col>
            <div lg={6} sm={4}><label className='item-date-card'>
                 {formatDateTime(notification.notificationSysdate)}
               </label>
           </div>
           </Col>      
       </Row>
       <Row>
         <div className='item-content-dialog h-100'>{notification.content}</div>
       </Row>
     </Container>
    )

}

export default NotificationCard
