import SideItem from './SideItem'
import { Link } from 'react-router-dom';
import logo from '../images/logo-mini.png'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AppContext from '../AppContext';
import ModalCloseButton from '../Component/ModalCloseButton';

const SideMenuSmall = (props) => {
    const { t } = useTranslation()
    const{ partnerList, selectedPartnerId, setSelectedPartnerId } = useContext(AppContext)
    const [openModal, setOpenModal] = useState(false)

    const changePartner = (e) => {
        setSelectedPartnerId(e.target.value);
    }
    return (
        <>
            <div className={props.show ? 'side-menu-1 main-open-1' : 'side-menu-1'} >
                <Link to="/"> 
                    <img
                        alt=''
                        src={logo}
                        className='logo-picture-mini mx-auto img-fluid d-block'
                    />
                </Link>
                <div className="side-content">
                    <div id="first-half">
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-home" to="/" label={t`pocetna`} />
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-location" to="/mjerna-mjesta/" label={t`mjernoMjesto`}></SideItem>
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-bill" to="/racuni/" label={t`racun`}></SideItem>
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-wallet" to="/uplate/" label={t`uplate`}></SideItem>
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-edit" to="/prijava-stanja/" label={t`prijavaStanja`}></SideItem>
                        <SideItem  clickHandler={props.clickHandler} display={true} icon="lnc-calculations" to="/kalkulator-potrosnje/" label={t`kalkulatorPotrosnje`}></SideItem>
                        <SideItem  display={true} icon="lnc-user" clickHandler={()=>setOpenModal(true)} label={t`krajnjiKupac`}></SideItem>
                    </div>
                </div>
                <Modal centered={true} isOpen={openModal}>
                        <ModalHeader className="modal-header" toggle={()=>setOpenModal(!openModal)}>{t`odaberitePartnera`} <ModalCloseButton onClickHandler={()=>setOpenModal(!openModal)}></ModalCloseButton></ModalHeader>
                        <ModalBody className="modalBody">
                        {partnerList.length > 1 ?
                                <Input style={{ background: 'transparent', color: 'black', width:'95%' }} className="mb-3 mx-auto mt-3" type="select" value={selectedPartnerId} name="select" onChange={changePartner}>
                                                    {partnerList.map( partner => 
                                                        <option style={{ background: 'gray', color: 'white' }} key={partner.partnerId} value={partner.partnerId}> {partner.partnerName }</option>)}

                                </Input> : ""} 
                        </ModalBody>
                    </Modal>
            </div>
        </>
    )
}

export default SideMenuSmall
