const PrivacyPolicyView = () => {
  return (
    <div className="wrapper-login">
    <div className="layer-login d-flex justify-content-center">
    <div className='privacy-policy w-50 mt-4'>
    <div>
        <h4 className="">Politika privatnosti za glavnu web stranicu</h4><br />
    </div>
    <p>Ova stranica se koristi za informisanje posjetilaca o našim politikama prikupljanja, korišćenja i otkrivanja ličnih podataka ako je neko odlučio da koristi naše Usluge.</p>
    <p>Ako odlučite koristiti naše Usluge, pristajete na prikupljanje i upotrebu podataka u skladu s ovom politikom. Lični podaci koje prikupljamo koriste se za pružanje i poboljšanje naših Usluga. Nećemo koristiti ili dijeliti vaše podatke ni sa kim, osim kako je opisano u ovoj Politici privatnosti.</p>
    <p>Izrazi korišteni u ovoj Politici privatnosti imaju ista značenja kao u našim Odredbama i uslovima, kojima se može pristupiti u vezi s našim proizvodima, rješenjima i uslugama, ako drugačije nije određeno ovom Politikom privatnosti.</p>
    <p>&nbsp;</p>
    <p><strong>Prikupljanje i upotreba podataka</strong></p>
    <p>Za bolje iskustvo, tokom korištenja naših usluga, možda ćemo od vas tražiti da nam dostavite određene podatke koji mogu lično da vas identifikuju. Informacije koje zatražimo zadržaćemo i koristiti ih kako je opisano u ovoj Politici privatnosti.</p>
    <p>Posjećivanje jedne od naših web stranica, registracija za jedan od naših događaja, saradnja s nama, pretplata na naše usluge, korištenje naše mobilne aplikacije, korištenje lokalnog softvera ili usluga u Cloud-u ili interakcija s nama na bilo koji drugi način nam omogućava da prikupljamo i obrađujemo lične podatke o vama. Snažno se zalažemo kako za zaštitu i čuvanje privatnosti svih prikupljenih podataka na ovaj način tako i za poštovanje važećih zakona u svakom trenutku.</p>
    <p>Takođe, Google Analytics koristimo da bismo stekli uvid i poboljšali funkcionalnost naše web stranice. Google Analytics je usluga web analitike. Web analiza je sakupljanje, prikupljanje i analiza podataka o ponašanju posjetilaca web lokacija.</p>
    <p>&nbsp;</p>
    <p><strong>Evidencija podataka</strong></p>
    <p>Želimo vas obavijestiti da se u slučaju korištenja naših Usluga mogu prikupiti neki podaci i informacije. Ova evidencija podataka može sadržavati informacije kao što su adresa internetskog protokola („IP“ adresa) vašeg uređaja, naziv uređaja, verzija operativnog sistema, konfiguracija aplikacije prilikom korištenja naše Usluge, vrijeme i datum upotrebe Usluge i druge statistike.</p>
    <p>&nbsp;</p>
    <p><strong>Kolačići</strong></p>
    <p>Kolačići su datoteke s malom količinom podataka koje se obično koriste kao anonimni jedinstveni identifikatori. Oni se šalju vašem pretraživaču s web lokacija koje posjećujete i pohranjuju se u internu memoriju uređaja.</p>
    <p>Neke naše Usluge ne koriste izričito ove „kolačiće“. Međutim, aplikacije mogu koristiti kod treće strane i biblioteke koje koriste „kolačiće“ za prikupljanje podataka i poboljšanje svojih usluga. Imate opciju da prihvatite ili odbijete ove kolačiće i znate kada se kolačići šalju na vaš uređaj. Ako odlučite odbiti naše kolačiće, možda nećete moći koristiti neke dijelove ove Usluge.</p>
    <p>Kolačići mogu prikupiti sljedeće podatke:</p>
    <ul>
    <li>datum i vrijeme;</li>
    <li>IP adresa;</li>
    <li>ime domena;</li>
    <li>vrsta korištenog pretraživača i operativnog sistema (ako pretraživač dozvoli);</li>
    <li>URL referentne stranice (ako pretraživač dozvoli);</li>
    <li>traženi objekat;</li>
    <li>status ispunjenosti zahtjeva;</li>
    <li>geografsku lokaciju; ili</li>
    <li>jezičke preference.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Dobavljači usluga</strong></p>
    <p>Možemo zaposliti kompanije treće strane i pojedince iz sljedećih razloga:</p>
    <ul>
    <li>da olakšaju naše Usluge;</li>
    <li>da pružaju Usluge u naše ime;</li>
    <li>da obavljaju usluge povezane sa Uslugama; ili</li>
    <li>da nam pomažu u analizi načina na koji se koriste naše Usluge.</li>
    </ul>
    <p>Korisnike ovih Usluga želimo obavijestiti da ove treće strane imaju pristup njihovim ličnim podacima. Razlog je izvršavanje zadataka koji su im dodijeljeni u naše ime. Međutim, oni su dužni da ne otkrivaju ili koriste informacije u bilo koje druge svrhe.</p>
    <p>&nbsp;</p>
    <p><strong>Sigurnost</strong></p>
    <p>Održavamo administrativne, tehničke i fizičke mjere zaštite stvorene za zaštitu vaših ličnih podataka od slučajnog, nezakonitog ili neovlaštenog uništavanja, gubitka, promjene, pristupa, otkrivanja ili upotrebe.</p>
    <p>Cijenimo vaše povjerenje u pružanju vaših ličnih podataka i iz tog razloga nastojimo koristiti komercijalno prihvatljiva sredstva za zaštitu istih. Ali imajte na umu da nijedan način prenosa putem Interneta ili način elektronskog pohranjivanja nije 100% siguran i pouzdan i ne možemo garantovati njegovu apsolutnu sigurnost.</p>
    <p>&nbsp;</p>
    <p><strong>Pravni osnov za obradu ličnih podataka</strong></p>
    <p>Vaše lične podatke obrađivaćemo na sljedećim pravnim osnovama:</p>
    <ul>
    <li>izvršavanje ugovora s vama u skladu sa članom 6 (1)(b) GDPR-a, na primjer kada imate poslovni odnos s nama ili ako ste se prijavili za prisustvo ili izlaganje na nekom događaju;</li>
    <li>naš legitimni interes u skladu sa članom 6 (1)(f), na primjer naša želja za poboljšanjem web stranice; da budete u toku sa našim poslovnim aktivnostima i događajima;</li>
    <li>vaš pristanak u skladu sa članom 6 (1)(a) GDPR-a, na primjer kada prihvatite upotrebu kolačića na web lokaciji;</li>
    <li>da poštujemo zakonsku obavezu kojoj smo podvrgnuti u skladu sa članom 6 (1)(c);</li>
    </ul>
    <p>&nbsp;</p>
    <p>Podatke obrađujemo prvenstveno u skladu sa Zakonom o zaštiti podataka o ličnosti (&#8220;Službeni glasnik BiH&#8221; 49/06, 76/11 i 89/11) i svim ostalim propisima koji uređuju ovu materiju u Bosni i Hercegovini i Republici Srpskoj.</p>
    <p>Saglasnost nosioca podataka u smislu Zakona o zaštiti ličnih podataka  (&#8220;Službeni glasnik Bosne i Hercegovine&#8221;, broj 49/2006, 76/2011 i 89/2011 &#8211; ispravka) podrazumijeva svaku konkretnu i svjesnu naznaku želje nosioca podataka datu slobodnom voljom kojom nosilac podataka daje svoj pristanak da se njegovi lični podaci obrađuju.</p>
    <p>Prihvatanjem kolačića na web lokaciji, nosilac podataka takođe prihvata LANACO Politiku privatnosti i daje saglasnost za obradu njegovih ličnih podataka u skladu sa članom 5. Zakona o zaštiti ličnih podataka podataka  (&#8220;Službeni glasnik Bosne i Hercegovine&#8221;, broj 49/2006, 76/2011 i 89/2011 &#8211; ispravka) i u skladu sa članom 6 (1)(a) Opšte uredba o zaštiti podataka  (EU) 2016/679 (GDPR).</p>
    <p><strong> </strong></p>
    <p><strong>Linkovi do ostalih web lokacija</strong></p>
    <p>Naše Usluge mogu sadržavati linkove do drugih web lokacija. Ako kliknete na link treće strane, bićete preusmjereni na tu web lokaciju. Imajte na umu da ovim vanjskim web lokacijama ne upravljamo mi. Stoga vam toplo preporučujemo da pregledate Politiku privatnosti ovih web stranica. Nemamo kontrolu i ne preuzimamo nikakvu odgovornost za sadržaj, politike privatnosti ili prakse bilo kojih nezavisnih web lokacija ili usluga.</p>
    <p>&nbsp;</p>
    <p><strong>Dječija privatnost</strong></p>
    <p>Naše Usluge se ne odnose na osobe mlađe od 13 godina. Ne prikupljamo svjesno podatke koji mogu lično da identifikuju djecu mlađu od 13 godina. U slučaju da otkrijemo da nam je dijete mlađe od 13 godina dalo lične podatke, odmah iste brišemo sa svojih servera. Ako ste roditelj ili staratelj i svjesni ste da nam je Vaše dijete dostavilo lične podatke, kontaktirajte nas kako bismo mogli poduzeti potrebne radnje.</p>
    <p>&nbsp;</p>
    <p><strong>Promjene u ovoj politici privatnosti</strong></p>
    <p>S vremena na vrijeme možemo ažurirati našu Politiku privatnosti. Stoga vam savjetujemo da povremeno pregledate ovu stranicu radi bilo kakvih promjena. Obavijestićemo vas o svim promjenama objavljivanjem nove Politike privatnosti na ovoj stranici.</p>
    <p>Ova politika je na snazi od 14.07.2021.</p>
    <p>&nbsp;</p>
    <p><strong>Kontaktiraj nas</strong></p>
    <p className="p-0">Ako imate bilo kakvih pitanja ili prijedloga u vezi s našom Politikom privatnosti, ne ustručavajte se kontaktirati nas na sljedeću adresu elektronske pošte: servicedesk@lanaco.com.</p>
  </div></div></div>);
};

export default PrivacyPolicyView;
