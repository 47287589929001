import React, { useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import LoginView from './route_login/LoginView';
import MainView from './main/MainView';
import { AppContext } from './AppContext';
import ProtectedRoute from './authentication/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundView from './route_login/NotFoundView';
import { createTheme , ThemeProvider} from '@mui/material/styles';
import PrivacyPolicyView from './route_pocetna/PrivacyPolicyView';
import "intro.js/introjs.css";


function App() {
    const [crmUser, setCrmUser] = useState()
    const [picture, setPicture] = useState(null)
    const [messageNumber, setMessageNumber] = useState(null)
    const [partnerList, setPartnerList] = useState([])
    const [selectedPartnerId, setSelectedPartnerId] = useState(null)
    const [mjernoMjestoList, setMjernoMjestoList] = useState([]);
    const [tenant,setTenant] = useState(null);
    const [language, setLanguage] = useState('cir');
    
    const theme = createTheme({
        palette: {
          primary: {
            light: 'lightgray',
            main: '#F19021',
            dark: 'lightgray',
            contrastText: '#153a78',
          },
          secondary: {
            light: 'white',
            main: 'white',
            dark: 'white',
            contrastText: 'white',
          },
        },
      });

    return (
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
            <AppContext.Provider value={{ crmUser,setCrmUser, picture, setPicture, messageNumber,
                setMessageNumber, partnerList, setPartnerList, selectedPartnerId, setSelectedPartnerId,
                mjernoMjestoList, setMjernoMjestoList,language,setLanguage,tenant,setTenant
            }}>
                <ToastContainer
                    position="top-right"
                    theme="colored"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route path="/login/:tenant" component={LoginView} />
                        <Route path="/login" component={NotFoundView} />
                        <Route path='/politika-privatnosti' component={PrivacyPolicyView} />
                        <ProtectedRoute path="/" component={MainView}/>
                    </Switch>
                </ThemeProvider>
            </AppContext.Provider>
        </BrowserRouter> 
    )
}

export default App
