import React from 'react'
import { Col, Row } from 'reactstrap'

const FeatureComponent = (props) => {
    
    let iconClass = 'ponuda-img ml-4 ml-md-5 pl-md-0 pl-lg-5 lnc '.concat(props.icon)

    return (
        <div className="d-flex flex-column ponuda-p w-100 mt-12">
                <Row>
                    <div className="w-10">
                    <i className={iconClass}></i>
                    </div>
                    <Col className="w-90">
                        <h5>{props.title}</h5>
                        <p>{props.text}</p>
                    </Col>
                </Row>
            </div>
    )
}

export default FeatureComponent
