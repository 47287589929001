import React, { useState }  from 'react'
import { useTranslation } from 'react-i18next'
import { Input, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter,Button,Form,Col, Row } from 'reactstrap'
import axios from '../utils/axios'
import { AUTH_SIGN_UP } from '../const'
import { toast } from 'react-toastify';
import ModalCloseButton from '../Component/ModalCloseButton'

const SignUpView = (props) => {
    const [formData, setFormData] = useState({
        email: '',
        emailInvalid: false,
        emailErrorMessage:'',
        firstName: '',
        firstNameInvalid: false,
        firstNameErrorMessage:'',
        lastName: '',
        lastNameInvalid: false,
        lastNameErrorMessage:'',
        partnerCode: '',
        partnerCodeInvalid: false,
        partnerCodeErrorMessage: '',
        address: '',
        addressInvalid: false,
        addressErrorMessage:'',
        phone: '',
        phoneInvalid: false,
        phoneErrorMessage:''
    })
    const[successModalOpen, setSuccessModalOpen] = useState(false)
    const{ t } = useTranslation();
    
    const onCloseSuccessModal = (e) => {
        setSuccessModalOpen(false);
        props.showLoginDialog();
    }

    const handleChangeFormData = (e) => {
        const value = e.target.value;
        setFormData({
          ...formData,
          [e.target.name]: value
        });
    }

    const validateForm = () => {
        let invalidFields = false;
        let tempFormData = {};
       
        if (formData.email === null || formData.email === undefined || formData.email.trim() === '') {
            tempFormData = {
                ...tempFormData,
                emailInvalid: true,
                emailErrorMessage:t`requiredField`
            }
            invalidFields = true
        } else {
            const validEmail = new RegExp("[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$")
            if (validEmail.test(formData.email)) {
                tempFormData = {
                    ...tempFormData,
                    emailInvalid: false,
                }
            } else {
                tempFormData = {
                    ...tempFormData,
                    emailInvalid: true,
                    emailErrorMessage:t`emailFormat`
                }
                invalidFields = true
            }
        }

        if (formData.firstName === null|| formData.email === undefined || formData.firstName.trim() === '') {
            tempFormData = {
                ...tempFormData,
                firstNameInvalid: true,
                firstNameErrorMessage:t`requiredField`
            }
            invalidFields = true
        } else {
            tempFormData = {
                ...tempFormData,
                firstNameInvalid: false,
            }
        }

        if (formData.lastName === null || formData.email === undefined ||  formData.lastName.trim() === '') {
            tempFormData = {
                ...tempFormData,
                lastNameInvalid: true,
                lastNameErrorMessage:t`requiredField`
            }
            invalidFields = true
        } else {
            tempFormData = {
                ...tempFormData,
                lastNameInvalid: false,
            }
        }

        if (formData.address === null || formData.email === undefined|| formData.address.trim() === '') {
            tempFormData = {
                ...tempFormData,
                addressInvalid: true,
                addressErrorMessage:t`requiredField`
            }
            invalidFields = true
        } else {
            tempFormData = {
                ...tempFormData,
                addressInvalid: false,
            }
        }

        if (formData.partnerCode === null || formData.email === undefined|| formData.partnerCode.trim() === '') {
            tempFormData = {
                ...tempFormData,
                partnerCodeInvalid: true,
                partnerCodeErrorMessage:t`requiredField`
            }
            invalidFields = true
        } else {
            tempFormData = {
                ...tempFormData,
                partnerCodeInvalid: false,
            }
        }

        setFormData({ ...formData, ...tempFormData });
        return !invalidFields
    }

    const okAction = (e) => {
        e.preventDefault();
        if(validateForm())
            newAcount()
    }

    const newAcount = () => {
        axios.post(AUTH_SIGN_UP, {
            "username": formData.email,
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "partnerCode": formData.partnerCode,
            "requestDate":null,
            "phone": formData.phone,
            "address": formData.address,
            "tenantId":props.selectedTenant
        })
        .then(resp => {
            setSuccessModalOpen(true)
        })
        .catch(err => {
            if (err.response) {
                console.log(t(err.response.data.message))
                toast.error(t(err.response.data.message))
            }
        })
    }

    return(
        <Form>
        <h1 style={{color:'black', fontSize:'24px'}}>{t`registracijaNovogNaloga`}</h1>
            <div className="d-flex flex-row mb-3">
                <span style={{ color: "red" }} className="d-block mt-2">{formData.firstName == "" ? "*" : ""}</span>
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"  
                        placeholder={t`ime`}
                        type="text"
                        name='firstName'
                        value = {formData.firstName}
                        onChange={handleChangeFormData}
                        invalid={formData.firstNameInvalid}
                    />
                    <FormFeedback className="w-100">{formData.firstNameErrorMessage}</FormFeedback>
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <span style={{ color: "red" }} className="d-block mt-2">{formData.lastName == "" ? "*" : ""}</span>
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"
                        placeholder={t`prezime`}
                        type="text"
                        name='lastName'
                        value = {formData.lastName}
                        onChange={handleChangeFormData}
                        invalid={formData.lastNameInvalid}
                    />
                    <FormFeedback className="">{formData.lastNameErrorMessage}</FormFeedback>
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <span style={{ color: "red" }} className="d-block mt-2">{formData.address == "" ? "*" : ""}</span>
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"
                        placeholder={t`adresa`}
                        type="text"
                        name='address'
                        value={formData.address}
                        onChange={handleChangeFormData}
                        invalid={formData.addressInvalid}
                    />
                    <FormFeedback className="">{formData.addressErrorMessage}</FormFeedback>
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"
                        placeholder={t`brojTelefona`}
                        type="number"
                        pattern="[0-9]*"
                        name='phone'
                        value={formData.phone}
                        onInput={handleChangeFormData}
                    />
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <span style={{ color: "red" }} className="d-block mt-2">{formData.email == "" ? "*" : ""}</span>
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"
                        placeholder={t`email`}
                        type="text"
                        name='email'
                        value={formData.email}
                        onChange={handleChangeFormData}
                        invalid={formData.emailInvalid}
                    />
                    <FormFeedback className="">{formData.emailErrorMessage}</FormFeedback>
                </div>
            </div>
            <div className="d-flex flex-row mb-3"> 
                <span style={{ color: "red" }} className="d-block mt-2">{formData.partnerCode == "" ? "*" : ""}</span>
                <div className="d-flex flex-column w-100">
                    <Input
                        style={{width: "90%"}}
                        className="fieldsLine"
                        placeholder={t`partnerCode`}
                        type="text"
                        value={formData.partnerCode} 
                        name='partnerCode'
                        onInput={handleChangeFormData}
                        invalid={formData.partnerCodeInvalid}
                    />
                    <FormFeedback className="">{formData.partnerCodeErrorMessage}</FormFeedback>
                </div>
            </div>  
                <Row className='p-xs-0 p-xl-12 mt-2 mr-1'>
                    <Col>
                        <button
                            type="button"
                            className='btn dark-button'
                            onClick={props.showLoginDialog}
                            >{t`nazad`}</button>
                    </Col>
                    <Col>
                        <button 
                            className='btn dark-button  float-right' 
                            onClick={okAction}
                            type="submit">
                            {t`potvrdi`}</button>
                    </Col>
                </Row>   
                <Modal centered={true} isOpen={successModalOpen}>
                    <ModalHeader  className="modal-header"> {t`uspjesnaRegistracijaHeader`} <ModalCloseButton onClickHandler={()=>setSuccessModalOpen(!successModalOpen)}></ModalCloseButton></ModalHeader>
                    <ModalBody className="modalBody p-3">
                        {t`uspjesnaRegistracijaText`}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="submit-button" onClick={onCloseSuccessModal}>{t`potvrdi`}</Button>
                    </ModalFooter>
                </Modal>    
        </Form>
    )
}

export default SignUpView
