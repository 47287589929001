import React from 'react'
import { Row } from 'reactstrap';
import MainBody from './MainBody'

function MainContent(props) {
    return (
        <Row className="main-main-content main-no-margin">
            <MainBody {...props}/>
        </Row>
    )
}

export default MainContent