import { useState, useEffect, useContext } from 'react'
import { PAYMENTS_FIND_ALL } from '../const'
import { Table } from 'reactstrap'
import { Spinner } from 'reactstrap'
import './UplateView.css'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import PaymentBox from './PaymentBox'
import { useTranslation } from 'react-i18next'
import AppContext from '../AppContext'
import axios from '../utils/axios'

const UplateView = (props) => {
    const[loaded, setLoaded] = useState()
    const[payments, setPayments] = useState()
    const{ t } = useTranslation()
    const { selectedPartnerId } = useContext(AppContext);

  useEffect(() => {
      axios.get(PAYMENTS_FIND_ALL(selectedPartnerId))
      .then(resp =>{
          setPayments(resp.data)
          setLoaded(true)
      })
      .catch(error => {
          console.log("Greška:" + error)
          console.log(error)
      })
      
  }, [selectedPartnerId])

    if (loaded) {
      let paymentList = payments.map(item => <TableRow key={item.id} item={item} />)
      let paymentsBox = payments.map(item => <PaymentBox key={item.id} item={item} />)
        return (
            <div className="container-fluid">
              <div>
                <h4 className="payment-page-title">{t`uplate`}</h4><br />
              </div> 
              <br />
              <div className="row payment-table-view">
                <Table className="payment-table" size="sm" borderless striped>
                  <TableHeader />
                  <tbody>
                    {paymentList}
                  </tbody>
                </Table>
              </div>
              <div className="payment-box-view">
                {paymentsBox}
              </div>
            </div>
        )
      }
      return (
        <Spinner className="payment-spiner" color="primary" />
      )
}

export default UplateView
