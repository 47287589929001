export const formatDate = value => {
  let date = new Date(value)

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }
  return `${day}.${month}.${year}`
}

export const formatDateTime = value => {
  let date = new Date(value)

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  let hour = date.getHours()
  let minut = date.getMinutes()

  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }

  if (hour < 10) {
    hour = '0' + hour
  }
  if (minut < 10) {
    minut = '0' + minut
  }
  return `${day}.${month}.${year}   ${hour}:${minut}`
}

export const formatNumber = value => {
  return Number(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
