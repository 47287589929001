import { formatDate } from "../../utils/formater";

function TableBodyView(props) {
    return (
        <tr>
            <td>{props.item.waterMeterNumber}</td>
            <td>{formatDate(props.item.dateMeasurement)}</td>
            <td className="text-right">{props.item.quantity}</td>
        </tr>
    )
}

export default TableBodyView
