import { formatDate } from '../utils/formater'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';

const TableRow = (props) => {

    const{ t } = useTranslation()

    return (
    <tr>
        <td className="bill-td">
            <Link className=""  to={`/notification/${props.notification.notificationId}`}>
                {!props.flagRead ? (
                    <strong onClick = {props.checkNotificationNumber} className='item-header'>{props.notification.header}</strong>
                ):( <strong className='item-header'>{props.notification.header}</strong>)}
            </Link>
        </td>
        <td className="bill-td">{formatDate(props.notification.notificationSysdate)}</td>
        <td className="bill-td" style={{maxWidth:'400px',overflow: 'hidden' ,textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{props.notification.content} </td>
        <td className="bill-td d-flex justify-content-center">
        {props.notification.flagRead ? (
                        <div>
                            {props.notification.flagRead}
                        </div>
                    ) : (
                        <Tooltip followCursor={true} title={t`readNotification`}>
                            <div className='' onClick={()=> props.readNotification(props.notification.notificationId)}>
                                <FontAwesomeIcon style={{color:"#153a78"}} icon={faEyeSlash} className='icon2' />
                                {props.notification.flagRead}
                            </div>
                        </Tooltip>
                    )}
        </td>
    </tr>
    )
}

export default TableRow
