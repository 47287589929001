import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authenticationService } from './AuthenticationService'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            if(authenticationService.isLoggedIn()){
                return <Component />
            } else {
                return <Redirect to={{ pathname: "/login"}}/>
            }
        }}/>
    )
}

export default ProtectedRoute
