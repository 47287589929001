import CustomButton from '../Component/CustomButton'
import { formatNumber } from '../utils/formater'
import { useTranslation } from 'react-i18next'

const TableRow = (props) => {

    const{ t } = useTranslation()

    return (
    <tr>
        <td className="bill-td">{props.item.period}</td>
        <td className="bill-td">{props.item.invNumber}</td>
        <td className="bill-td bills-value">{formatNumber(props.item.amount)}</td>
        <td className="bill-td">
            <CustomButton dark={true} text={t`preuzmiRacun`} icon="lnc-download" onClick={props.downloadAction} />
        </td>
    </tr>
    )
}

export default TableRow
