import { useState, useEffect, useContext } from 'react'
import { Table } from 'reactstrap'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { NOTIFICATION_FIND_ALL,ALL_NOTIFICATION_READ,ONE_NOTIFICATION_READ } from '../const'
import Pagination from '@mui/material/Pagination'
import './Notifications.css'
import NotificationItem from './NotificationItem'
import axios from '../utils/axios'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../AppContext';
import { Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye} from '@fortawesome/free-solid-svg-icons';


const AllNotifications = () => {
    const[notifications, setNotifications] = useState([])
    const [pageSize, setPageSize] = useState(12)
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(1)
    const { messageNumber, setMessageNumber } = useContext(AppContext);

    const { t }  = useTranslation()

    useEffect(() => {
        findAllNotification();
    }, [page]);

    const findAllNotification = () => {
        axios.get(NOTIFICATION_FIND_ALL(page - 1, pageSize))
            .then(resp => {
                setNotifications(resp.data.content)
                setPageCount(resp.data.totalPages)
            })
            .catch(error => {
                console.log("Greška:" + error)
                console.log(error)
            });
    }
    
    const readAllNotification = () => {
        axios.put(ALL_NOTIFICATION_READ)
          .then(resp => {
              setMessageNumber(0);
              findAllNotification();
              console.log(resp)
          })
          .catch(error => {
            console.log("Greška:" + error)
          })
    }

    
    const checkNotificationNumber = () => {
        if(messageNumber === 1 || messageNumber === 0 || messageNumber === null){
            setMessageNumber(0)
        }else{
            setMessageNumber(messageNumber-1)
        }
    }

    
    const readNotification = (notificationId) => {    
        axios.put(ONE_NOTIFICATION_READ(notificationId))
        .then(resp => {
            checkNotificationNumber();
            findAllNotification();
            console.log(resp)
        })
        .catch(error => {
          console.log("Greška:" + error)
        })
  }
  
    let notificationList = notifications.map(notification => <TableRow key={notification.notificationId}
        notification={notification}
        checkNotificationNumber={checkNotificationNumber}
        readNotification={readNotification}>
        </TableRow>);
    
    const displayNotifications = notifications.map((notification) => {
        return(<div className="container-fluid">
            <NotificationItem 
                key={notification.notificationId} 
                findAllNotification={findAllNotification} 
                readNotification={readNotification}
                item={notification}
                className="w-50"
            />
        </div>);
    })

    const buttonSeeAll =
        <Tooltip  title={t`readAllNotification`}>
            <button className="btn dark-button" onClick={readAllNotification}>
                <FontAwesomeIcon style={{ fontSize: "1.5em" }} className="" icon={faEye} />
            </button>
        </Tooltip>;
        
    return (
        <div className="container-fluid">
                <div>
                    <h4 className="payment-page-title">{t`obavjestenja`}</h4><br />
                </div>
                <div className="notification-table-view" style={{minHeight:'65vh',position:'relative',width:'100%'}}>
                    <Table className="bill-table" size="sm" borderless striped >
                        <TableHeader buttonSeeAll={buttonSeeAll} />
                        <tbody>
                            {notificationList}
                        </tbody>
                        <tfoot id="">
                            <tr className='w-100'>                            
                                <td colSpan={4} className='w-100'>
                                    <Pagination id="pagination-component" showFirstButton showLastButton className='d-flex justify-content-center' count={pageCount} color='primary' size='large' shape="rounded" page={page} onChange={(event, val) => setPage(val)} />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            <div className='notification-box-view'>
                    <div className='w-100 mb-3 d-flex justify-content-end'>
                        {buttonSeeAll}
                    </div>
                    {displayNotifications}
                    <div className='pagination-wrapper-small d-flex justify-content-center'>
                        <Pagination id="pagination-component-small" count={pageCount} color='primary' size='large' shape="rounded" page={page} onChange={(event, val) => setPage(val)} />
                    </div>
                </div>
           
      </div> 
    )
 }

export default AllNotifications
