import { Table, Card, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next'

function UtrosakComponent(props) {
    const { t } = useTranslation();
 
    return (
        <Container fluid className="p-0">
            <Col lg={12} xs='12' className="p-0">
                <div>
                    {props.naslov}
                    <Card className="modalWin">
                        <Table size="sm" borderless striped>
                            <tbody>
                                <tr>
                                    <td className="modalTableLeft">{t`utrosak`}</td>
                                    <td className="modalTableRight">{props.data.quantity}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaZagadjenjaVode`}</td>
                                    <td className="modalTableRight">{props.data.nzv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaZagadjenjaVodePdv`}</td>
                                    <td className="modalTableRight">{props.data.nzv_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaIskoristenjaVode`}</td>
                                    <td className="modalTableRight">{props.data.niv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaIskoristenjaVodePdv`}</td>
                                    <td className="modalTableRight">{props.data.niv_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaOdrzavanjeMjernogMjesta`}</td>
                                    <td className="modalTableRight">{props.data.nom}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`naknadaOdrzavanjeMjernogMjestaPdv`}</td>
                                    <td className="modalTableRight">{props.data.nom_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`cijenaKanalizacija`}</td>
                                    <td className="modalTableRight">{props.data.price_sewerage}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`cijenaKanalizacijaPdv`}</td>
                                    <td className="modalTableRight">{props.data.price_sewerage_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`cijenaVode`}</td>
                                    <td className="modalTableRight">{props.data.price_water}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`cijenaVodePdv`}</td>
                                    <td className="modalTableRight">{props.data.price_water_pdv}</td>
                                </tr>
                                
                                <tr>
                                    <td className="modalTableLeft">{t`ukupnoBezPdv`}</td>
                                    <td className="modalTableRight">{props.data.sum_without_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`ukupnoPdv`}</td>
                                    <td className="modalTableRight">{props.data.sum_pdv}</td>
                                </tr>
                                <tr>
                                    <td className="modalTableLeft">{t`ukupnoSaPdv`}</td>
                                    <td className="modalTableRight">{props.data.sum_with_pdv}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </div>
            </Col>
        </Container>
    )
}

export default UtrosakComponent
