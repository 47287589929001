import { formatDate, formatNumber } from '../utils/formater'
import { Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const PaymentBox = (props) => {

    const{ t } = useTranslation()

    return (
        <div className="payment-box">
            <Table borderless size="sm" striped>
                <tbody>
                    <tr>
                        <td className="text-font-size payment-td-box-left">{t`datumUplate`}</td>
                        <td className="text-font-size payment-td-box-right"> {formatDate(props.item.paymentDate)}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size payment-td-box-left">{t`iznosKM`}</td>
                        <td className="text-font-size payment-td-box-right">{formatNumber(props.item.amount)}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size payment-td-box-left">{t`mjestoPlacanja`}</td>
                        <td className="text-font-size payment-td-box-right">{props.item.placePayment}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size payment-td-box-left">{t`napomena`}</td>
                        <td className="text-font-size payment-box-value payment-td-box-right">{props.item.note}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default PaymentBox
