import { useTranslation } from "react-i18next"

const TableHeader = (props) => {

    const { t } = useTranslation()
    
    return (
        <thead className="bill-thead">
        <tr>
            <th style={{width:'20%'}} className="bill-th">{t`naslov`}</th>
            <th style={{width:'10%'}} className="bill-th">{t`datum`}</th>
            <th style={{width:'50%'}} className="bill-th">{t`tekst`}</th>
            <th style={{ width: '5%' }} className="bill-th">{ props.buttonSeeAll }</th>
        </tr>
    </thead>
    )
}

export default TableHeader
