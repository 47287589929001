import { Input, InputGroup } from 'reactstrap'
import logo from '../images/logo-primarni.svg'
import './LoginView.css'
import { useTranslation } from 'react-i18next'

const LoginFormComponent = (props) => {

    const{ t } = useTranslation()
    
    return (
            <form>
                <img alt=''
                    src={logo}
                    className='logo mx-auto img-fluid d-block col-md-8 col-xl-12'
                />
                <hr className="horizontalLine" />
                <h5 className="w-100 mb-1 mb-md-5 mt-1 mt-md-4 text-center text-dark">{t`prijavaVelikaSlova`}:</h5>


                <div className="row col-12 nopadding d-flex justify-content-between user-pass">
                    <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                        <Input placeholder={t`korIme`} name="username" className="w-75 fieldsLine" onChange={props.handleUsernameOnChange}></Input>
                    </InputGroup>

                    <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                        <Input placeholder={t('lozinka')} name="password" type="password" className="w-75 fieldsLine" onChange={props.handlePasswordOnChange}></Input>
                </InputGroup>
                </div>

                <div className="text-center">
                    <button className="btn dark-button mt-3 mb-1 mb-md-4 buttonLogin" onClick={props.handleSubmit}>{t`ulogujSeVelikaSlova`}</button> 
                </div>
                <label className="w-100 mb-0 mb-md-2 labelForgotPassword text-center forgotPassword" onClick={props.changeResetPassword}>{t`zaboraviliSteLozinku`}</label>
                <label className="w-100 mb-0 mb-md-2 labelForgotPassword text-center register" onClick={props.changeSignUp}>{t`registrujteSe`}</label>
                <label className="w-100 mb-0 mb-md-2 labelForgotPassword text-center verification" onClick={props.changeVerifyAccount}>{t`verifikacijaNaloga`}</label>
            </form>
    );
}

export default LoginFormComponent
