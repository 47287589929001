import { useState , useContext,useEffect }from 'react';
import './MeasuringPointsView.css'
import MeasuringPointView from './MeasuringPointView';
import { Input, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import AppContext from '../AppContext';
import { useTranslation } from 'react-i18next'

function MeasuringPointsView() {
    const { t } = useTranslation();
    const { mjernoMjestoList } = useContext(AppContext);
    const [filteredMeasuringPoints, setFilteredMeasuringPoints] = useState([]);
    
    const searchHandler = e => {
        let value = e.target.value;
        if (value.length > 0) {
            let filteredArray = mjernoMjestoList.filter(item => {
                return String(item.measurementPoint.toLowerCase()).includes(value.toLowerCase());
            });
            setFilteredMeasuringPoints(filteredArray);
        } else {
            setFilteredMeasuringPoints(mjernoMjestoList);
        }
    }
    
    useEffect(() => {
        setFilteredMeasuringPoints(mjernoMjestoList)
    }, [mjernoMjestoList]);
    

    return (
            <div className="container-fluid">
                <div>
                    <h4 className="payment-page-title">{t`mjernoMjesto`}</h4><br />
                </div>
                <SearchBar searchHandler={searchHandler} />
                {filteredMeasuringPoints &&  filteredMeasuringPoints.map((item,index) => <MeasuringPointView key={item.id + '-'+index} item={item} />)}
            </div>);
    }


const SearchBar = props => {
    const { t } = useTranslation();
    return (
        <div className="row measuring-point-box">
            <div className="col">
                <InputGroup>
                    <FontAwesomeIcon style={{color:  "#153a78",fontSize:"1.5em"}} className="my-auto mr-4" icon={faSearch} />
                    <Input type="text" onChange={props.searchHandler} id="search" placeholder={t`searchBarPlaceholder` } />
                </InputGroup>
            </div>
        </div>
    )
};

export default MeasuringPointsView
