import { useState, useContext, useEffect } from 'react';
import KalkulatorPotrosnjeForma from './KalkulatorPotrosnjeForma';
import { Input, Row } from 'reactstrap';
import "./KalkulatorPotrosnjeView.css";
import { AppContext } from '../AppContext';
import { GET_READING_LAST } from '../const';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

function KalkulatorPotrosnjeView() {
    const { t } = useTranslation();
    const { mjernoMjestoList } = useContext(AppContext);
    const [ocitanoStanje, setOcitanoStanje] = useState();
    const [serialNumber, setSerialNumber] = useState();
    const [selectedMeasuringPointId, setSelectedMeasuringPointId] = useState(0); 
    const [formEnabled, setFormEnabled] = useState(true);
    const [quantity, setQuantity] = useState()

    const getPoslednjeOcitanoStanje = mjernoMjestoId => {
        if (mjernoMjestoId === 0) {
            return;
        }
        else {
            axios.get(GET_READING_LAST(mjernoMjestoId)).then(resp => {
                setOcitanoStanje(resp.data);
            }).catch(error => {
                console.log("Greska:" + error)
                console.log(error)
            });
        } 
    }
    
    useEffect(() => {
        setQuantity('')
        if (mjernoMjestoList.length > 0) {
            setSelectedMeasuringPointId(mjernoMjestoList[0].measurementPointId)
            getPoslednjeOcitanoStanje(mjernoMjestoList[0].measurementPointId);
            setSerialNumber(mjernoMjestoList[0].serialNumber)
            if (mjernoMjestoList[0].serialNumber == '-') {
                setFormEnabled(false)
            } else {
                setFormEnabled(true)
            }
        } else {
            setSelectedMeasuringPointId(null);
            setOcitanoStanje(null);
            setSerialNumber(null)
        }
    }, [mjernoMjestoList]);
    
    const mjernoMjestoOnChange = (event) => {
        const tempSelectedMjernoMjesto = mjernoMjestoList.find(mm => mm.measurementPointId === Number(event.target.value));
        setSerialNumber(tempSelectedMjernoMjesto.serialNumber);
        getPoslednjeOcitanoStanje(tempSelectedMjernoMjesto.measurementPointId);
        setSelectedMeasuringPointId(tempSelectedMjernoMjesto.measurementPointId);
        if (tempSelectedMjernoMjesto.serialNumber == '-') {
            setFormEnabled(false)
        } else {
            setFormEnabled(true)
        }
        setQuantity('')
    }

    const insertQuantityValue = (event) => {
        setQuantity(event.target.value)
    }   
    return (     
        <div className="w-100 p-1 overflow-hidden">
            <h4 className="titleKalkulatorPotrosnje text-lightgray">{t`kalkulatorPotrosnje`}</h4><br></br>
            <div className="form-group w-100">
                <div className="justify-content-start">
                <Row>
                    <label className="text-lightgray mt-1 col-auto">{t`mjernoMjesto`}:</label>
                    <div className="col-12 col-md-12 col-xl-5">
                    <Input  disabled={mjernoMjestoList.length > 1 ? false : true} className="text form-control-sm" onChange={mjernoMjestoOnChange} type="select" id="mjerno-mjesto">
                        {mjernoMjestoList.length >0 && mjernoMjestoList.map(mm => {
                                return <option key={mm.measurementPointId} value={mm.measurementPointId}>{mm.address}</option>
                            })
                        }
                    </Input>
                    </div>
                </Row>
                    <Row className="pt-3 pl-3">
                        <div>
                            <label className="text-lightgray w-50 d-inline mr-3">{t`brojMjernogUredjaja`}:</label>
                            <label className="text text-lightgray">{serialNumber}</label>
                        </div>
                    </Row>
                
                <Row className="p-3">
                    <div>
                        <label className="text-lightgray">{t`posljednjeOcitanoStanje`} (м³):</label>
                        <label className="text text-lightgray">{ocitanoStanje}</label>
                    </div>   
                </Row> 
                </div>
            <br></br>

            </div>
            <KalkulatorPotrosnjeForma
                formEnabled={formEnabled}
                ocitanoStanje={ocitanoStanje}
                selectedMeasurementPointId={selectedMeasuringPointId}
                quantity={quantity}
                insertQuantityValue={insertQuantityValue}
            ></KalkulatorPotrosnjeForma>
            </div>
    )
}

export default KalkulatorPotrosnjeView
