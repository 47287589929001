import "./custombutton.css"

function CustomButtonPrimary(props) {
    let iconClass = "custom-button-icon lnc ".concat(props.icon)

    return (
        <button {...props} style={props.style} type="button" className={props.className} onClick={props.onClick} disabled={props.disabled}><i className={iconClass}></i>{props.text}</button>
    )
}

export default CustomButtonPrimary