import { Input } from "reactstrap"
import './PrijavaStanjaView.css'
import { useTranslation } from 'react-i18next'

const PrijavaNovogStanja = (props) => {
    const{ t } = useTranslation()

    return (
        <div className="card whole-box">
            <h5 className="card-header header-box text text-center">{t`prijavaNovogStanja`}</h5>
            <div className="card-body body-box d-flex flex-column">
                <label className={(props.isAlreadySubmitted) ? "text-center m-auto" : "d-none"}>{t`stanjeJePrijavljeno`}!</label>
                <div className="d-flex flex-sm-row flex-md-column flex-lg-row justify-content-center">
                    <label className="text-blue d-inline mt-2 mr-3">{t`stanje`} (м³):</label>
                    <Input disabled={!props.formEnabled} value={props.prijavljenoStanje} onChange={props.novoStanjeOnChange} className="font-weight-bold w-50 d-inline" type="number"></Input>
                </div>
            </div>
        </div> 
    )
}

export default PrijavaNovogStanja
