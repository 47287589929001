// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nbody {\n  margin: 0;\n  padding: 0;  \n  font-family: 'Montserrat' !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Montserrat' !important;\n}\n.container-fluid{\n  padding: 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAGA;EACE,SAAS;EACT,UAAU;EACV,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,qBAAqB;AACvB","sourcesContent":["\n@import url('https://fonts.googleapis.com/css?family=Montserrat');\n\nbody {\n  margin: 0;\n  padding: 0;  \n  font-family: 'Montserrat' !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Montserrat' !important;\n}\n.container-fluid{\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
