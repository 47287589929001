import React from 'react';
import "./custombutton.css"

function CustomButton(props) {
    let iconClass = "custom-button-icon lnc ".concat(props.icon)

        let buttonClass;
        if (props.dark) {
            buttonClass = "custom-button-payment custom-button-dark";
        } else {
            buttonClass = "custom-button-payment custom-button-normal";
        }
        if(props.disabled){
            buttonClass = "custom-button-payment custom-button-normal custom-button-disabled"
        }

    return (
        <button style={props.style} type="button" className={buttonClass} onClick={props.onClick} disabled={props.disabled}><i className={iconClass}></i>{props.text}</button>
    )
}

export default CustomButton