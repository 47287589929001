import { useTranslation } from 'react-i18next'

const Utrosak = (props) => {
        
    const{ t } = useTranslation()

    return (
        <div className="card whole-box">
            <h5 className="card-header header-box text text-center">{t`utrosak`}</h5>
            <div className="card-body body-box d-flex justify-content-center">
                <label className="text-blue">{t`stanje`} (м³): {props.utrosak > 0 ? props.utrosak : "0"}</label>
            </div>
        </div>
    )
}

export default Utrosak
