import React, {useState,useEffect,useContext} from 'react';
import { Container, Row, Col } from "reactstrap";
import './Homeview.css';
import { formatDate, formatNumber } from '../utils/formater';
import InfoBox from './InfoBox';
import axios from '../utils/axios';
import { GET_SALDO, LAST_INVOICE_URL, LAST_PAYMENT_URL, PARTNER_GET_INFO } from '../const';
import { useTranslation } from 'react-i18next';
import AppContext from '../AppContext';

function HomeView() {
  const { selectedPartnerId, crmUser } = useContext(AppContext);
  const [partner, setPartner] = useState({
    partnerId: null,
    email: null,
    phone: null,
    partnerCode:null,
    name: null,
    address: null,
    consumptionCategory: null,
    flagEmail: null,
    flagPost:null,
    emails: [],
    invoiceOnEmail: null,
    invoiceOnPost:null
  });
  const [paymentData, setPaymentData] = useState({
    id: null,
    amount: null,
    note: null,
    paymentDate: null,
    placePayment: null,
    bank:null
  });
  const [invoiceData, setInvoiceData] = useState({
    id: null,
    amount: null,
    period: null,
    invNumber:null
  });
  const [balanceData, setBalanceData] = useState(null);
  const { t } = useTranslation();
  
  
  useEffect(() => {
    if (selectedPartnerId) {
      axios.get(PARTNER_GET_INFO(selectedPartnerId))
        .then(resp => {
          setPartner({
            ...resp.data,
            emails: resp.data.emails ? resp.data.emails.split(';') : [],
            email: crmUser.email
          });
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [selectedPartnerId,crmUser]);

  useEffect(() => {
    if (selectedPartnerId) {
      axios.get(LAST_INVOICE_URL(selectedPartnerId)).then(resp => {
        setInvoiceData(resp.data);
      }).catch(error => {
        console.log("Greska:" + error)
        console.log(error)
        setInvoiceData({
          id: null,
          amount: null,
          period: null,
          invNumber: null
        })
      })
    }
  }, [selectedPartnerId])

  useEffect(() => {
    if (selectedPartnerId) {
      axios.get(LAST_PAYMENT_URL(selectedPartnerId)).then(resp => {
        setPaymentData(resp.data);
      }).catch(error => {
        console.log("Greska:" + error)
        console.log(error)
        setPaymentData({
          id: null,
          amount: null,
          note: null,
          paymentDate: null,
          placePayment: null,
          bank:null
        })
      })
    }
  }, [selectedPartnerId]);

  useEffect(() => {
    if (selectedPartnerId) {
      axios.get(GET_SALDO(selectedPartnerId)).then(resp => {
        setBalanceData(resp.data);
      }).catch(error => {
        console.log("Greska:" + error)
        console.log(error)
      })
    }
  }, [selectedPartnerId]);
  

  return (
      <>
        <Container fluid className="home-content" >
          <Row>
            <InfoBox className="infobox" id="home-box4" label={t`sifraKorisnika`} value={partner.partnerCode != null ? partner.partnerCode : "-"} icon="lnc lnc-scan-number" />
            <InfoBox id="home-box1" className={balanceData > 0 ?"infobox stanje-negativ":"infobox"} label={t`trenutnoStanje`} value={formatNumber(balanceData)} sufix=" KM" icon="lnc lnc-file-text" />
            <InfoBox id="home-box2" className="infobox" label={t`poslRacun`} value={invoiceData.amount != null ?formatNumber(invoiceData.amount) + " KM":"-"}  icon="lnc lnc-bill" />
            <InfoBox id="home-box3" className="infobox" label={t`poslednjaUpl`} value={paymentData.paymentDate != null ? formatNumber(paymentData.amount) + " KM":"-"} icon="lnc lnc-wallet" />
          </Row>
          <Row className="mt-4">
          <Col sm="12" md="12" lg="6" xl="3" className='p-1'>
            <div className="card">
              <div className="card-header header-box d-flex">
                <i className="home-info-card-icon lnc lnc-user fa-2x mr-3"></i>
                <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`krajnjiKupac`}</h4>
              </div>
              <Row className="card-body body-box d-flex flex-column">
                <h6 className="home-info-card-h6">{t`naziv`}</h6>
                <h5 className="home-info-card-h5 text-uppercase text-wrap">{partner.name != null ? partner.name: "-"}</h5>
                <h6 className="home-info-card-h6">{t`adresa`}</h6>
                <h5 className="home-info-card-h5 text-uppercase text-wrap">{partner.address != null ? partner.address: "-"}</h5>
                <h6 className="home-info-card-h6">{t`kategorija`}</h6>
                <h5 className="home-info-card-h5">{partner.consumptionCategory  != null ? partner.consumptionCategory: "-"}</h5>
                <h6 className="home-info-card-h6">{t`email`}</h6>
                <h5 className="home-info-card-h5 text-wrap">{partner.email ? partner.email : "-"}</h5>
                <h6 className="home-info-card-h6">{t`telefon`}</h6>
                <h5 className="home-info-card-h5">{partner.phone ? partner.phone : "-"}</h5>
              </Row>
            </div>
          </Col>

          <Col sm="12" md="12" lg="6" xl="3" className="mt-5 mt-md-0 p-1">
            <div className="card whole-box">
              <div className="card-header header-box d-flex">
                <i className="home-info-card-icon lnc lnc-mail mr-3"></i>
                <h4 className="home-h4 home-font home-info-card-label text-uppercase">{t`dostavaFakt`}</h4>
              </div>
              <Row className="card-body body-box d-flex flex-column">
                <h6 className="home-info-card-h6">{t`nacinDostaveFakture`}</h6>
                <h5 className="home-info-card-h5">{partner.flagPost ?  t`nacinDostavePostom`: ""}</h5>
                <h5 className="home-info-card-h5">{partner.flagEmail ? t`nacinDostaveMejlom` : ""}</h5>
                {partner.emails.length === 0?"":<h6 className="home-info-card-h6">{t`spisakMejlova`}</h6>}
                {partner.emails.map((email) => {
                  return <h5 key={email} className="home-info-card-h5 "> {email ? email : ""} </h5>
                })}
              </Row>
            </div>
          </Col>
          <Col sm="12" md="12" lg="6" xl="3" className="mt-5 mt-md-0 p-1">
            <div className="card whole-box">
              <div className="card-header header-box d-flex">
                <i className="home-info-card-icon lnc lnc-bill fa-2x mr-3"></i>
                <h4 className="home-h4 home-font home-info-card-label text-uppercase">{t`poslRacun`}</h4>
              </div>
              <Row className="card-body body-box d-flex flex-column">
                <h6 className="home-info-card-h6">{t`period`}</h6>
                <h5 className="home-info-card-h5">{invoiceData.period != null ? invoiceData.period:"-"}</h5>
                <h6 className="home-info-card-h6">{t`iznos`}</h6>
                <h5 className="home-info-card-h5">{invoiceData.amount != null ? formatNumber(invoiceData.amount) + " KM":"-"}</h5>
              </Row>
            </div>
          </Col>

          <Col sm="12" md="12" lg="6" xl="3" className="mt-5 mt-md-0 p-1" >
            <div className="card whole-box">
              <div className="card-header header-box d-flex">
                <i className="home-info-card-icon lnc lnc-wallet mr-3"></i>
                <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`poslednjaUpl`}</h4>
              </div>
              <Row className="card-body body-box d-flex flex-column">
                <h6 className="home-info-card-h6">{t`datum`}</h6>
                <h5 className="home-info-card-h5">{paymentData.paymentDate != null ? formatDate(paymentData.paymentDate):"-"}</h5>
                <h6 className="home-info-card-h6">{t`iznos`}</h6>
                <h5 className="home-info-card-h5">{paymentData.amount != null ? formatNumber(paymentData.amount) + " KM":"-"}</h5>
                <h6 className="home-info-card-h6">{t`banka`}</h6>
                <h5 className="home-info-card-h5">{paymentData.bank != null ? paymentData.bank :"-"}</h5>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
      )
}

export default HomeView;
