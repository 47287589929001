import React from 'react';

export const AppContext = React.createContext({
    crmUser: null,
    setCrmUser: () => { },
    picture: null,
    setPicture: () => { },
    messageNumber: null,
    setMessageNumber: () => { },
    selectedPartnerId: null,
    setSelectedPartnerId:()=>{},
    partnerList:[],
    setPartnerList: () => { },
    mjernoMjestoList: [],
    setMjernoMjestoList: () => { },
    language: '',
    setLanguage: () => { },
    tenant: null,
    setTenant:()=>{}
});
export default AppContext
