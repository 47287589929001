export const BASE_URL = process.env.REACT_APP_API_END_POINT;
//promjeniti putanju


/////////////////////////////////////CRM-USER//////////////////////////////////////

//http://localhost:10210/crm-portal-services/crm-user/info
export const CRM_USER_GET_INFO = BASE_URL + "/crm-user/info";


/////////////////////////////////////////////////////////////////////////////////////////////////////////

//http:localhost:10210/crm-portal-services/crm-user/picture

export const GET_USER_PICTURE = BASE_URL + "/crm-user/picture";

//http://localhost:10210/crm-portal-services/crm-user/picture

export const CHANGE_USER_PICTURE = BASE_URL + "/crm-user/change-picture";

//http://localhost:10210/crm-portal-services/crm-user/change-language

export const CHANGE_LANGUAGE = BASE_URL + "/crm-user/change-langugage"

export const VERIFY_PARTNER = BASE_URL + "/crm-user/activate-partner-code"

//http://localhost:10210/crm-portal-services/app-user/change-password
export const CHANGE_PASSWORD = BASE_URL + "/crm-user/change-password";

export const CHANGE_SETTINGS = BASE_URL + "/crm-user/change-settings";

export const CHANGE_INVOICE_ON_EMAIL = (partnerId, invoiceOnEmailEnabled) => BASE_URL + "/crm-user-partner/set-invoice-on-email?partnerId=" + partnerId + "&invoiceOnEmailEnabled=" + invoiceOnEmailEnabled;

export const DEACTIVATE_PARTNER = (partnerId) => BASE_URL + "/crm-user-partner/disable?partnerId=" + partnerId;

///////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////////NOTIFICATIONS//////////////////////////////////////

//http://localhost:10210/crm-portal-services/notification/all?pageNumber=0&pageSize=10 

export const NOTIFICATION_FIND_ALL = (pageNumber, pageSize) => BASE_URL + "/notification/all?pageNumber="+ pageNumber +"&pageSize="+ pageSize;

//http://localhost:10210/crm-portal-services/notification/1

export const ONE_NOTIFICATION = (notificationId) => BASE_URL + "/notification/" + notificationId;

//http://localhost:10210/crm-portal-services/notification/set-read/1
export const ONE_NOTIFICATION_READ = (notificationId) => BASE_URL + "/notification/set-read/" + notificationId;

//http://localhost:10210/crm-portal-services/notification/set-read
export const ALL_NOTIFICATION_READ = BASE_URL + "/notification/set-read";

//http://localhost:10210/crm-portal-services/notification/unread-count?flagRead=0
export const NOTIFICATION_UNREAD_COUNT = BASE_URL + "/notification/unread-count?flagRead=0";


////////////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////////AUTH///////////////////////////////////////////////

export const AUTH_LOGIN = BASE_URL + "/auth/login";

export const AUTH_LOGOUT = BASE_URL + "/auth/logout";

export const AUTH_SIGN_UP = BASE_URL + "/auth/sign-up";

export const AUTH_VERIFY = BASE_URL + "/auth/verify";

export const AUTH_FORGOT_PASSWORD = BASE_URL + "/auth/account-recovery";

export const AUTH_GET_ALL_TENANTS = BASE_URL + "/auth/tenant"

////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////INOVICE///////////////////////////////////////////////

//http://localhost:10210/crm-portal-services/12611/invoice/all
export const INVOICES_FIND_ALL = (partnerId) => BASE_URL + "/"+partnerId+"/invoice/all";

export const INVOICE_GET = (partnerId, fileId) => BASE_URL + "/" + partnerId + "/invoice/"+ fileId;

export const RACUN_PDF_URL = (fileId) => "http://app.in-track.tech/file-system/file-content/"  + fileId;

export const INVOICE_URL = (customerNumber) => BASE_URL + "/" + customerNumber + "/invoice";

//http://localhost:10210/crm-portal-services/55520/invoice/last
export const LAST_INVOICE_URL = (partnerId) => BASE_URL + "/"+partnerId+"/invoice/last";

////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////PAYMENT///////////////////////////////////////////////

//http://localhost:10210/crm-portal-services/16734/payment/all
export const PAYMENTS_FIND_ALL = (partnerId) => BASE_URL + "/"+partnerId+"/payment/all";

//http://localhost:10210/crm-portal-services/16734/payment/last
export const LAST_PAYMENT_URL = (partnerId) => BASE_URL + "/"+ partnerId +"/payment/last";

////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////CALCULATE-CONSUMPTION///////////////////////////////////////////////

export const CALCULATE_MEASUREMENT = BASE_URL + "/calculate/measurement";

export const CALCULATE_CONSUMPTION = BASE_URL + "/calculate/consumption";

/////////////////////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////PARTNER//////////////////////////////////////////////////////////
//saldo?
//http://localhost:10210/crm-portal-services/partner/16734/balance
export const GET_SALDO = (partnerId) => BASE_URL + "/partner/" + partnerId + "/balance";

//http://localhost:10210/crm-portal-services/partner/16734/info
export const PARTNER_GET_INFO = (partnerId) => BASE_URL + "/partner/"+ partnerId +"/info";

/////////////////////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////MEASUREMENT-POINT//////////////////////////////////////////////////////

//http://localhost:10210/crm-portal-services/measurement-point/all?partnerId=12611
export const MEASUREMENT_POINTS_FIND_ALL = (partnerId) => BASE_URL + "/" + partnerId +"/measurement-point/all";

//http://localhost:10210/crm-portal-services/12510/measurement/last
export const MEASURING_POINT_LAST_MEASUREMENT = (measuringPointId) => BASE_URL + "/"+ measuringPointId +"/measurement/last";

export const MJERNA_MJESTA_ALL_URL = partnerId => BASE_URL +"/"+partnerId+ "/measurement-point/all";

//http://localhost:10210/crm-portal-services/12510/measurement/all
export const GET_READING = measurementPointId => BASE_URL + "/"+ measurementPointId +"/measurement/all";

//http://localhost:10210/crm-portal-services/measurement/sumbit
export const SUBMIT_USER_MEASUREMENT = BASE_URL + "/measurement/submit"; 


export const GET_READING_LAST = measurementPointId => BASE_URL + "/" + measurementPointId + "/measurement/last";

export const GET_CRM_USER_PARTNER_LIST = BASE_URL + "/crm-user-partner/all";

export const ADD_PARTNER_CODE = BASE_URL + "/crm-user/add-partner-code";

/////////////////////////////////////////////////////////////////////////////////////////////////////////
