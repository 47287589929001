import React from 'react';
import { useTranslation } from 'react-i18next';

function TableHeader({ turnedOff, validContract }) {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="inline">
                {turnedOff ?
                    (
                        <div className="inline red">
                            <i className="header-icon  lnc lnc-x"></i>
                            <label>{t`mmInactive`.toUpperCase()}</label>
                        </div>
                    ) : (
                        <div className="inline green">
                            <i className="header-icon lnc lnc-check"></i>
                            <label>{t`mmActive`.toUpperCase()}</label>
                        </div>
                    )}
            </div>         
        </div>
    )
}

export default TableHeader
