import jwtDecode from "jwt-decode"

const isLoggedIn = ()=> {
    if(localStorage.getItem('token') != null)
    return true
    else
    return false
}

const logout= ()=> {
    localStorage.removeItem('token')
}

const getTenant = () => {
    if (localStorage.getItem('token')) {
        let jwt = localStorage.getItem('token');
        const token = jwtDecode(jwt);
        return token.tenantId
    }
    return null;
}

export const authenticationService = {
    isLoggedIn,
    logout,
    getTenant
}