import { useState, useEffect, useContext, useRef } from 'react'
import AppContext from '../AppContext'
import { Table } from 'reactstrap'
import { INVOICES_FIND_ALL, INVOICE_GET  } from '../const'
import { Spinner } from 'reactstrap'
import './RacuniView.css'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import BillBox from './BillBox'
import { useTranslation } from 'react-i18next'
import axios from '../utils/axios'
import { get as getData } from 'axios'
import { toast } from 'react-toastify'

const RacuniView = (props) => {
    const [loaded, setLoaded] = useState(false)
    const [urlPdf,setUrlPdf] = useState()
    const [invoices, setInvoices] = useState([
        {
            id: null,
            amount: null,
            invNumber: null,
            period: null,
            fileId:null
        }
    ])
    const { selectedPartnerId, tenant } = useContext(AppContext);
    const { t } = useTranslation();
    const linkRef = useRef(null);
    
    useEffect(() => {
        axios.get(INVOICES_FIND_ALL(selectedPartnerId))
        .then(resp =>{ 
            setInvoices(resp.data)
            setLoaded(true)
        })
        .catch(error => {
            console.log(error)
        }) 
    }, [selectedPartnerId])

    const preuzmiRacun = async (fileId) => {
        axios.get(INVOICE_GET(selectedPartnerId, fileId))
        .then(resp =>{ 
            const base64Content = resp.data.content;
            const decodedContent = window.atob(base64Content); 
            const uint8Array = new Uint8Array(decodedContent.length);
            for (let i = 0; i < decodedContent.length; i++) {
              uint8Array[i] = decodedContent.charCodeAt(i);
            }
           const blob = new Blob([uint8Array], { type: 'application/pdf' });
           const fileURL = URL.createObjectURL(blob);
           const link = document.createElement('a');
           link.href = fileURL;
           link.download = 'file.pdf';
           document.body.appendChild(link);
           link.click();
       
           // Clean up the temporary URL
           URL.revokeObjectURL(fileURL);
        })
        .catch(error => {
            console.log(error);
            toast.error(t("pdfNotFound"));
        }) 
    }

    if (loaded) {
        let bills = invoices.map(item => <TableRow key={item.id} item={item} downloadAction={() => preuzmiRacun(item.fileId)} />)
        let billsBox = invoices.map(item => <BillBox key={item.id} item={item} downloadAction={() => preuzmiRacun(item.fileId)} />)
        return (
            <div className="container-fluid">
                <div>
                    <h4 className="" style={{color:"lightgrey",fontWeight:'bold'}}>{t`racun`}</h4><br />
                </div>
                <div className="row">
                    <div className="col">
                        <h5 className="bill-activ-title" style={{color:"lightgrey",fontWeight:'bold'}}>{t`aktivacijaRacunaTitle`} </h5>
                    </div>
                </div>
                <a ref={linkRef} href={urlPdf} download="racun.pdf" className="d-none">Preuzmi</a>
                <hr />
                <p className="bill-activ-content" style={{color:"lightgrey",fontWeight:'bold'}}>{t`aktivacijaRacunaText`}</p>
                <div className="bill-table-view">
                    <Table className="bill-table" size="sm" borderless striped>
                        <TableHeader />
                        <tbody>
                          {bills}
                        </tbody>
                    </Table>
                </div>
                <div className="bill-box-view">
                    {billsBox}
                </div>
            </div>
        )
    } 
    return (
        <Spinner className="bill-spiner" color="primary" />
    )
}

export default RacuniView
