import React from 'react'
import { Input, Table } from "reactstrap";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
import TableBodyView from './TableBodyView';
import TableHeaderView from './TableHeaderView';
import { formatDate } from "../../utils/formater";
import { useTranslation } from 'react-i18next';

function EnergyCard(props) {
    const { t } = useTranslation();

    if (props.chartDataFetched) {
        let tableViewData = props.filteredChartData.map(item => <TableBodyView key={item.waterMeterNumber} item={item} />);
        let chart = <ResponsiveContainer className="mt-2" height={300}>
                        <BarChart data={props.filteredChartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dateMeasurement" tickFormatter={formatDate} />
                            <YAxis />
                            <Tooltip labelFormatter={formatDate?formatDate:'-'} />
                            <Legend />
                            <Bar name={t`stanje`} dataKey="quantity" fill="#153a78" />
                        </BarChart>
                    </ResponsiveContainer>;
    return (
            <div className="container-fluid">
                     <label className='d-block w-100 m-1'>{t`zaPeriod`}</label>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <Input
                                disabled
                                value={formatDate(props.startDate)}
                                className="fieldsLine" 
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <Input
                                disabled
                                value={formatDate(props.endDate)}
                                className="fieldsLine" 
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                    {props.filteredChartData.length > 0 ? chart:null}
                    <div>
                        <Table striped bordered responsive size="sm">
                            <TableHeaderView />
                            <tbody>
                                {tableViewData}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <div></div>
    }
}

export default EnergyCard;
