import { useTranslation } from 'react-i18next'
import { Input} from 'reactstrap'
import CustomButtonPrimary from '../Component/CustomButtonPrimary';

const VerificationForm = (props) => {
    const { t } = useTranslation();
    return <>
        <Input
            className="fieldsLine"
            placeholder={t`verifikacioniKod`}
            type='text'
            name='verificationCode'
            value={props.verificationCode}
            onChange={e=>props.setVerificationCode(e.target.value)}
        />
        <div className="settings-modal-button mt-2">
            <CustomButtonPrimary className="mb-2 dark-button" text={t`potvrdi`} icon="lnc-check" onClick={props.submitModalReguestHandler}></CustomButtonPrimary>
        </div>
  </>;
};

export default VerificationForm;
