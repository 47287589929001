import React from 'react';
import WarningSignImage from '../images/triangle-warning-sign.png'
import { useTranslation } from 'react-i18next'

const NotFoundView = () =>{
const { t } = useTranslation();
return (
        <div className='wrapper'>
            <div className='layer'>
                <div className='container d-flex align-items-center'>
                    <img width="250px" height="200px" src={WarningSignImage} alt=""/>
                <p style={{ fontSize: "2em", fontWeight: "bold", marginLeft: "3em" }}>{t`pageNotFound`}</p>
                </div>
            </div>
    </div>
    )
}
export default NotFoundView;
