import { useState, useEffect, useContext } from 'react';
import PrijavaStanjaForma from './PrijavaStanjaForma';
import './PrijavaStanjaView.css';
import { GET_READING_LAST } from '../const';
import { Input, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';
import AppContext from '../AppContext';
import axios from '../utils/axios'


const PrijavaStanjaView = () => {
    const { t } = useTranslation()
    const { mjernoMjestoList } = useContext(AppContext);
    const [ocitanoStanje, setOcitanoStanje] = useState(0)
    const [prijavljenoStanje, setPrijavljenoStanje] = useState(null)
    const [selectedMjernoMjestoId, setSelectedMjernoMjestoId] = useState()
    const [serialNumber, setSerialNumber] = useState();
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
    const [hasWaterMeter,setHasWatermeter]= useState(true)
    const [formEnabled, setFormEnabled] = useState(true)
    const [utrosak, setUtrosak] = useState()

    const { selectedPartnerId } = useContext(AppContext);

      const getPoslednjeOcitanoStanje = (mjernoMjestoId) => {
        axios.get(GET_READING_LAST(mjernoMjestoId))
        .then(resp => {
          setOcitanoStanje(resp.data)
        })
        .catch(err => {
          console.log(err)
          setOcitanoStanje('')
        })  
      }
  
      useEffect(() => {
        setPrijavljenoStanje('');
        setUtrosak('')
        if (mjernoMjestoList.length > 0) {
          let selectedMeasuringPointTemp = mjernoMjestoList[0]; 
          let hasWaterMeter = selectedMeasuringPointTemp.serialNumber !== '-' && selectedMeasuringPointTemp.serialNumber !==null && selectedMeasuringPointTemp.serialNumber!== undefined;
          let formEnabled = hasWaterMeter;
          setSelectedMjernoMjestoId(selectedMeasuringPointTemp.measurementPointId);
          getPoslednjeOcitanoStanje(selectedMeasuringPointTemp.measurementPointId);
          setSerialNumber(selectedMeasuringPointTemp.serialNumber)
          setIsAlreadySubmitted(false);
          setFormEnabled(formEnabled)
          setHasWatermeter(hasWaterMeter)
        } else {
          setSelectedMjernoMjestoId(null);
          setOcitanoStanje(null);
          setSerialNumber('-');
          setFormEnabled(false);
        }
      }, [mjernoMjestoList]);

      const mjernoMjestoOnChange = (event) => {
          let selectedMeasuringPointTemp = mjernoMjestoList.find(mjernoMjesto => mjernoMjesto.measurementPointId === Number(event.target.value)); 
          let hasWaterMeter = selectedMeasuringPointTemp.serialNumber !== '-' && selectedMeasuringPointTemp.serialNumber !==null && selectedMeasuringPointTemp.serialNumber!== undefined;
          let formEnabled = hasWaterMeter;
          setIsAlreadySubmitted(false);
          setSelectedMjernoMjestoId(selectedMeasuringPointTemp.measurementPointId)
          setSerialNumber(selectedMeasuringPointTemp.serialNumber)
          setFormEnabled(formEnabled)
          setHasWatermeter(hasWaterMeter)
          setPrijavljenoStanje('')
          setUtrosak('')
          getPoslednjeOcitanoStanje(selectedMeasuringPointTemp.measurementPointId)
      }
        const novoStanjeOnChange = (event) => {
          let novoStanje = Number(event.target.value)
          let utrosak = novoStanje - ocitanoStanje
          setPrijavljenoStanje(novoStanje)
          setUtrosak(utrosak)
        }
 
        return (
          <div className="container-fluid">
            <h4 className="titlePrijavaStanja">{t`prijavaStanja`}</h4><br></br>
              <Col className="p-0">
                <Row>
                  <label className="text-lightgray mt-1 col-auto">{t`mjernoMjesto`}:</label>
                  <div className="col-12 col-md-12 col-xl-5">
                    <Input type='select' disabled={mjernoMjestoList.length > 1 ? false : true} onChange={mjernoMjestoOnChange} className="text form-control-sm" id="mjerno-mjesto">
                      {mjernoMjestoList.length > 0 && mjernoMjestoList.map(mm => {
                        return <option className="text" key={mm.measurementPointId} value={mm.measurementPointId}>{mm.address}</option>
                      })}
                    </Input>            
                  </div>
                </Row>
                <Row className="pt-3">
                    <div>           
                      <label className="text-lightgray w-50 ml-3 d-inline mr-3">{t`brojMjernogUredjaja`}:</label>
                      <label className="text text-lightgray">{serialNumber}</label>
                    </div>
                </Row>
                <Row className={hasWaterMeter?"d-none":"d-block"}>
                  <div>           
                      <label className="w-50 ml-3 d-inline mr-3 text-danger font-weight-bold">{t`mjestoNemaVodomjer`}</label>
                  </div>
                </Row>
              </Col><br></br>
              <PrijavaStanjaForma 
                novoStanjeOnChange={novoStanjeOnChange} 
                utrosak={utrosak}
                formEnabled={formEnabled}
                setFormEnabled={setFormEnabled}
                isAlreadySubmitted={isAlreadySubmitted}
                setIsAlreadySubmitted={setIsAlreadySubmitted}
                prijavljenoStanje={prijavljenoStanje}
                ocitanoStanje={ocitanoStanje}
                partnerId={selectedPartnerId}
                mjernoMjestoId={selectedMjernoMjestoId}>
              </PrijavaStanjaForma>
          </div>
        )
}

export default PrijavaStanjaView