import { useTranslation } from "react-i18next"

const TableHeader = (props) => {

    const { t } = useTranslation()
    
    return (
        <thead className="bill-thead">
        <tr>
            <th className="bill-th">{t`period`}</th>
            <th className="bill-th">{t`brojRacuna`}</th>
            <th className="bill-th text-right">{t`iznosKM`}</th>
            <th className="bill-th">{t`racun`}</th>
        </tr>
    </thead>
    )
}

export default TableHeader
