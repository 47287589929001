import { useTranslation } from 'react-i18next'

const TableHeader = () => {

    const{ t } = useTranslation()

    return (
        <thead className="payment-thead">
            <tr>
                <th className="payment-th">{t`datum`}</th>
                <th className="payment-th text-right">{t`iznosKM`}</th>
                <th className="payment-th text-left">{t`mjestoPlacanja`}</th>
                <th className="payment-th text-left">{t`napomena`}</th>
            </tr>
      </thead>
    )
}

export default TableHeader
