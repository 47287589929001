import React, { useState, useEffect,useContext } from 'react';
import { Table } from 'reactstrap'
import TableHeader from './TableHeader';
import { useTranslation } from 'react-i18next';
import EnergyCard from './energyCard/EnergyCard';
import AppContext from '../AppContext';
import axios from '../utils/axios';
import { GET_READING } from '../const';
import CustomButtonPrimary from '../Component/CustomButtonPrimary';
import ModalCloseButton from '../Component/ModalCloseButton';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

function MeasuringPointView(props) {
    const { mjernoMjestoList } = useContext(AppContext);
    const [chartDataFetched, setChartDataFetched ] = useState(false);
    const [startDate, setStartDate ] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime());
    const [endDate, setEndDate] = useState(new Date().getTime());
    const [filteredChartData, setFilteredChartData] = useState([]);
    const [openModal, setOpenModal] = useState(false)
  
    const { t } = useTranslation();

    const filterChartData = () => {
        axios.get(GET_READING(props.item.measurementPointId)).then(resp => {
            if (resp.data) {
                setFilteredChartData(resp.data);
                setChartDataFetched(true);
            }
          }).catch(error => {
            console.log("Greska:" + error)
            console.log(error)
          }) 
    }

    useEffect(() => {
        filterChartData();
    }, [mjernoMjestoList])

    const handleChangeStart = date => {
        setStartDate(date);
        filterChartData();
    }
    const handleChangeEnd = date => {
        setEndDate(date);
        filterChartData();
    }
    const headerData = {
        turnedOff: props.item.turnedOff,
        validContract: props.item.validContract
    }

    return (
        <div className="row measuring-point-box">
            <div className="col-md-6 col-sm-12">
                <TableHeader data={headerData} />
            </div>
            <div className="col-md-6 col-sm-12">
                <CustomButtonPrimary style={{ float: 'right' }} className={filteredChartData?"dark-button":"dark-button disabled"} text={t`karticaOcitanja`} icon="lnc-chart" onClick={()=>setOpenModal(!openModal)} />
            </div>
            <Table className="mp-table-border-top" size="sm" borderless striped>
                <tbody>
                    <tr>
                        <td className="text-font-size mp-title-label">{t`mjernoMjesto`}</td>
                        <td className=" text-font-size mp-bold-value"> {props.item.measurementPoint}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size mp-title-label">{t`katTarGrupa`}</td>
                        <td className="text-font-size mp-bold-value">{props.item.tariffGroup}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size mp-title-label">{t`brojMjernogUredjaja`}</td>
                        <td className="text-font-size mp-bold-value">{props.item.serialNumber}</td>
                    </tr>
                    <tr>
                        <td className="text-font-size mp-title-label">{t`adresa`}</td>
                        <td className="text-font-size mp-bold-value">{props.item.address}</td>
                    </tr>
                </tbody>
            </Table>
            <Dialog open={openModal} fullWidth maxWidth='md'>
                <DialogTitle>
                    {t`karticaOcitanja`} 
                    <ModalCloseButton onClickHandler={() => setOpenModal(!openModal)}></ModalCloseButton>
                </DialogTitle>
                <DialogContent>
                    <EnergyCard chartDataFetched={chartDataFetched} 
                        startDate={startDate} 
                        endDate={endDate} 
                        handleChangeStart={handleChangeStart} 
                        handleChangeEnd={handleChangeEnd} 
                        filteredChartData={filteredChartData} 
                        mmeId={props.item.id}></EnergyCard>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default MeasuringPointView
